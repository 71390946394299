<script setup lang="ts">
import { type AlertsResource, type CustomerResource } from '@/types/types.gen';
import AppLayout from '@/Layouts/AppLayout.vue';
import { Link } from '@inertiajs/vue3';
import { route } from 'ziggy-js';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';
import InputIcon from 'primevue/inputicon';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import Button from 'primevue/button';
import Column from 'primevue/column';
import IconField from 'primevue/iconfield';
import { FilterMatchMode } from '@primevue/core/api';
import { reactive } from 'vue';
import Tag from 'primevue/tag';
import { useAlertConfig } from '@/Pages/Alerts/config';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import NavCrumbs from '@/Components/NavCrumbs.vue';
const { translate } = useTranslate();

defineProps<{
  alerts: AlertsResource[];
  customer: CustomerResource;
}>();

const state = reactive({
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

const config = useAlertConfig();

const getCategory = (alertType: string) => {
  let alertConfig = config.find(alert => alert.type === alertType);
  return alertConfig?.category
    ? translate(`alerts.activity.categories.${alertConfig.category}`)
    : translate(`alerts.activity.categories.none`);
};

</script>
<template>
  <AppLayout :title="$t('alerts.listing.title')">
    <NavCrumbs
      :model="[{ label: $t('alerts.listing.title') }]"
    />

    <Card class="w-full">
      <template #content>
        <DataTable
          v-model:filters="state.filters"
          :value="alerts"
          :global-filter-fields="['type']"
          data-key="uuid"
          :sort-order=1
          sort-field="type"
        >
          <template #empty>{{ $t('tables.not_found') }}</template>
          <template #header>
            <div class="flex justify-between">
              <IconField>
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  :placeholder="$t('tables.search')"
                  v-model="state.filters.global.value"
                />
              </IconField>

              <Link :href="route('ui.alerts.create', [customer.uuid])" preserve-state>
                <Button :label="$t('global.buttons.add_new')" icon="pi pi-plus" />
              </Link>
            </div>
          </template>
          <Column
            :sortable="true"
            field="type"
            :header="$t('alerts.listing.table.header.alert')"
            filterField="type"
            headerClass="w-3/6"
          >
            <template #body="{ data }: { data: AlertsResource }">
              <div class="flex-col content-center">
                <div class="text-sm capitalize">{{ getCategory(data.type) }}</div>
                <div>
                   {{ $t(`alerts.activity.options.${data.type}.heading`) }}
                </div>
              </div>
            </template>
          </Column>
          <Column :header="$t('alerts.listing.table.header.frequency')" field="frequency">
            <template #body="{ data }: { data: AlertsResource }">
                <div class="font-medium leading-6 text-color">
                  {{ $t(`alerts.properties.frequency.options.${data.frequency}`) }}
                </div>
            </template>
          </Column>
          <Column :header="$t('alerts.listing.table.header.recipients')" field="recipients">
            <template #body="{ data }">
              <div class="font-medium leading-6 text-color">
                {{ data.channels.mail.recipients.length }}
              </div>
            </template>
          </Column>
          <Column :header="$t('alerts.listing.table.header.status')" field="recipients">
            <template #body="{ data }">
              <Tag
                class="capitalize cursor-default"
                :value="$t(data.is_enabled ? 'alerts.tags.status.on.value' : 'alerts.tags.status.off.value')"
                :severity="data.is_enabled ? 'success' : 'secondary'"
                v-tooltip.top="$t(data.is_enabled ? 'alerts.tags.status.on.tooltip' : 'alerts.tags.status.off.tooltip')"
              />
            </template>
          </Column>
          <Column field="edit">
            <template #body="{ data }">
              <div class="flex items-center flex-row-reverse">
                  <Link :href="route('ui.alerts.edit', {customer: customer.uuid, alert: data.uuid})">
                    <Button
                      text
                      icon="pi pi-pencil"
                      v-tooltip.left="$t('alerts.actions.edit_alert.tooltip')"
                    />
                  </Link>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </AppLayout>
</template>
