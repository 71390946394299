<script setup lang="ts">
import * as EmailValidator from 'email-validator';
import { reactive } from 'vue';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import FormGroup from '@/Components/FormGroup.vue';
import Button from 'primevue/button';
import DeleteConfirmButton from '@/Components/DeleteConfirmButton.vue';
import type { RecipientResource } from '@/types/types.gen';
import Tag from 'primevue/tag';
import Checkbox from 'primevue/checkbox';

const props = defineProps<{
  recipients: RecipientResource[];
}>();

const emits = defineEmits<{
  (e: 'change', items: RecipientResource[]): void;
}>();

const state = reactive({
  editingIndex: -1,
  invalid: false,
  emailInput: { email: '', is_subscribed: true } as RecipientResource,
  editingOriginalValue: { email: '', is_subscribed: true } as RecipientResource,
  recipients: props.recipients,
});
const removeItem = (index: number) => {
  state.recipients.splice(index, 1);
  state.editingIndex = -1;
  emits('change', state.recipients);
};

const editItem = (emailObject: RecipientResource) => {
  state.invalid = false;

  if (!EmailValidator.validate(emailObject.email)) {
    state.invalid = true;
    return;
  }

  state.editingIndex = -1;
  emits('change', state.recipients);
};

const submit = () => {
  state.invalid = false;

  if (!EmailValidator.validate(state.emailInput.email)) {
    state.invalid = true;
    return;
  }

  state.recipients.push(state.emailInput);
  emits('change', state.recipients);
  state.emailInput = { email: '', is_subscribed: true };
};

const cancel = () => {
  state.invalid = false;
  state.recipients[state.editingIndex] = state.editingOriginalValue;
  state.editingIndex = -1;
  state.recipients = props.recipients;
}

</script>

<template>
  <section>
    <form @submit.prevent="submit">
      <div class="flex flex-col gap-1">
        <InputGroup>
          <InputText
            v-model.trim="state.emailInput.email"
            :placeholder="$t('email_list.fields.email.placeholder')"
            :disabled="state.editingIndex !== -1"
          />
          <Button
            icon="pi pi-plus"
            :label="$t('global.buttons.add')"
            :disabled="state.editingIndex !== -1"
            severity="primary"
            type="submit"
          />
        </InputGroup>
        <div
          v-if="state.invalid && state.editingIndex === -1"
          class="text-sm text-danger-default"
        >
          {{ $t('email_list.fields.email.validation_message') }}
        </div>
      </div>
    </form>
    <ul class="mt-3 flex flex-col gap-1">
      <li v-for="(item, index) in recipients"
          :key="index"
          class="group bg-section-default px-3"
      >
        <div class="flex items-center justify-between">
          <div v-if="state.editingIndex !== index" class="break-all pr-4">
            {{ item.email }}
          </div>
          <form
            v-else
            @submit.prevent="editItem(recipients[index])"
            class="w-full"
          >
            <div class="flex flex-col gap-1 m-5">
              <FormGroup :label="$t('email_list.fields.email.input_label')">
                <template #element>
                  <InputText
                    v-model.trim="state.recipients[index].email"
                    :placeholder="$t('email_list.fields.email.placeholder')"
                  />
                  <div
                    v-if="state.invalid"
                    class="text-sm text-danger-default"
                    v-text="$t('email_list.fields.email.validation_message')"
                  />
                  <div v-else
                       class="text-sm"
                       v-text="$t('email_list.fields.email.example')"
                  />
                  <div class="my-2">
                    <Checkbox
                      v-model="state.recipients[index].is_subscribed"
                      :binary="true"
                    />
                    <label
                      class="ml-2"
                      v-text="$t('email_list.fields.email.subscribed_label')"
                    />
                  </div>
                  <div class="mt-5 flex justify-between">
                    <DeleteConfirmButton
                      size="small"
                      @delete="removeItem(index)"
                    />
                    <div class="flex gap-4">
                      <Button
                        size="small"
                        class="mr-1"
                        text
                        severity="secondary"
                        :label="$t('global.buttons.cancel')"
                        @click="cancel"
                      />
                      <Button
                        type="submit"
                        size="small"
                        class="mr-1"
                        severity="secondary"
                        :label="$t('global.buttons.save')"
                      />
                    </div>
                  </div>
                </template>
              </FormGroup>
            </div>
          </form>
          <div
            v-if="state.editingIndex !== index"
            class="flex gap-4"
          >
            <div class="flex items-center">
              <Tag
                class="capitalize cursor-default"
                :value="item.is_subscribed ? $t('email_list.fields.email.subscribed_label') : $t('email_list.fields.email.unsubscribed_label')"
                :severity="item.is_subscribed ? 'success' : 'danger'"
              />
            </div>
            <div>
              <Button
                @click="
                  state.editingIndex = index;
                  state.editingOriginalValue = recipients[index];
                  state.invalid = false;
                "
                icon="pi pi-pencil"
                severity="contrast"
                text
                rounded
              />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>
