import type { LatLngTuple } from 'leaflet';

export type CountryCoordinates = {
  [countryCode: string]: LatLngTuple;
};

const CountryCoordinates: CountryCoordinates = {
  AF: [33.9391, 67.7100],
  AL: [41.1533, 20.1683],
  DZ: [28.0339, 1.6596],
  AD: [42.5063, 1.5218],
  AO: [-11.2027, 17.8739],
  AR: [-38.4161, -63.6167],
  AM: [40.0691, 45.0382],
  AU: [-25.2744, 133.7751],
  AT: [47.5162, 14.5501],
  AZ: [40.1431, 47.5769],
  BS: [25.0343, -77.3963],
  BH: [26.0667, 50.5577],
  BD: [23.6850, 90.3563],
  BB: [13.1939, -59.5432],
  BY: [53.7098, 27.9534],
  BE: [50.5039, 4.4699],
  BZ: [17.1899, -88.4976],
  BJ: [9.3077, 2.3158],
  BT: [27.5142, 90.4336],
  BO: [-16.2902, -63.5887],
  BA: [43.9159, 17.6791],
  BW: [-22.3285, 24.6849],
  BR: [-14.2350, -51.9253],
  BN: [4.5353, 114.7277],
  BG: [42.7339, 25.4858],
  BF: [12.2383, -1.5616],
  BI: [-3.3731, 29.9189],
  CV: [16.5388, -23.0418],
  KH: [12.5657, 104.9910],
  CM: [7.3697, 12.3547],
  CA: [56.1304, -106.3468],
  CF: [6.6111, 20.9394],
  TD: [15.4542, 18.7322],
  CL: [-35.6751, -71.5430],
  CN: [35.4867, 101.9018],
  CO: [4.5709, -74.2973],
  KM: [-11.6455, -43.3333],
  CR: [9.7489, -83.7534],
  CI: [7.5400, -5.5471],
  HR: [45.1000, 15.2000],
  CU: [21.5218, -77.7812],
  CY: [35.1264, 33.4299],
  CZ: [49.8175, 15.4730],
  CD: [-4.0383, 21.7587],
  DK: [56.2639, 9.5018],
  DJ: [11.8251, 42.5903],
  DM: [15.4150, -61.3710],
  DO: [18.7357, -70.1627],
  EC: [-1.8312, -78.1834],
  EG: [26.8206, 30.8025],
  SV: [13.7942, -88.8965],
  GQ: [1.6508, 10.2679],
  ER: [15.1794, 39.7823],
  EE: [58.5953, 25.0136],
  ET: [9.1450, 40.4897],
  FK: [-51.7963, -59.5236],
  FM: [7.4256, 150.5508],
  FJ: [-17.7134, 178.0650],
  FI: [61.9241, 25.7482],
  FR: [46.2276, 2.2137],
  TF: [-49.2804, 69.3486],
  GA: [-0.8037, 11.6094],
  GM: [13.4432, -15.3101],
  GE: [42.3154, 43.3569],
  DE: [51.1657, 10.4515],
  GH: [7.9465, -1.0232],
  GR: [39.0742, 21.8243],
  GL: [71.7069, -42.6043],
  GD: [12.1165, -61.6790],
  GT: [15.7835, -90.2308],
  GN: [9.9456, -9.6966],
  GW: [11.8037, -15.1804],
  GY: [4.8604, -58.9302],
  HT: [18.9712, -72.2852],
  HN: [15.2000, -86.2419],
  HU: [47.1625, 19.5033],
  IS: [64.9631, -19.0208],
  IN: [20.5937, 78.9629],
  ID: [-0.7893, 113.9213],
  IR: [32.4279, 53.6880],
  IQ: [33.2232, 43.6793],
  IE: [53.1424, -7.6921],
  IL: [31.0461, 34.8516],
  IT: [41.8719, 12.5674],
  JM: [18.1096, -77.2975],
  JP: [36.2048, 138.2529],
  JO: [30.5852, 36.2384],
  KZ: [48.0196, 66.9237],
  KE: [-0.0236, 37.9062],
  KI: [-3.3704, 168.7340],
  KW: [29.3117, 47.4818],
  KG: [41.2044, 74.7661],
  LA: [19.8563, 102.4955],
  LV: [56.8796, 24.6032],
  LB: [33.8547, 35.8623],
  LS: [-29.6100, 28.2336],
  LR: [6.4281, -9.4295],
  LY: [26.3351, 17.2283],
  LI: [47.1660, 9.5554],
  LT: [55.1694, 23.8813],
  LU: [49.8153, 6.1296],
  MK: [41.6086, 21.7453],
  MG: [-18.7669, 46.8691],
  MW: [-13.2543, 34.3015],
  MY: [4.2105, 101.9758],
  MV: [3.2028, 73.2207],
  ML: [17.5707, -3.9962],
  MT: [35.9375, 14.3754],
  MH: [7.1315, 171.1845],
  MR: [21.0079, -10.9408],
  MU: [-20.3484, -57.5522],
  MX: [23.6345, -102.5528],
  MD: [47.4116, 28.3699],
  MC: [43.7384, 7.4246],
  MN: [46.8625, 103.8467],
  ME: [42.7087, 19.3744],
  MA: [31.7917, -7.0926],
  MZ: [-18.6657, 35.5296],
  MM: [21.9162, 95.9560],
  NA: [-22.9576, 18.4904],
  NR: [-0.5228, 166.9315],
  NP: [28.3949, 84.1240],
  NL: [52.1326, 5.2913],
  NC: [-20.9043, 165.6180],
  NZ: [-40.9006, 174.8860],
  NI: [12.8654, -85.2072],
  NE: [17.6078, 8.0817],
  NG: [9.0820, 8.6753],
  KP: [40.3399, 127.5101],
  NO: [60.4720, 8.4689],
  OM: [21.4735, 55.9754],
  PK: [30.3753, 69.3451],
  PW: [7.5150, 134.5825],
  PS: [31.9522, 35.2332],
  PA: [8.5380, -80.7821],
  PG: [-6.3150, 143.9555],
  PY: [-23.4425, -58.4438],
  PE: [-9.1900, -75.0152],
  PH: [12.8797, 121.7740],
  PL: [51.9194, 19.1451],
  PT: [39.3999, -8.2245],
  PR: [18.2208, -66.5901],
  QA: [25.3548, 51.1839],
  CG: [-0.2280, 15.8277],
  RO: [45.9432, 24.9668],
  RU: [61.5240, 105.3188],
  RW: [-1.9403, 29.8739],
  WS: [-13.7590, -172.1046],
  SM: [43.9424, 12.4578],
  ST: [0.1864, 6.6131],
  SA: [23.8859, 45.0792],
  SN: [14.4974, -14.4524],
  RS: [44.0165, 21.0059],
  SC: [-4.6796, 55.4920],
  SL: [8.4606, -11.7799],
  SG: [1.3521, 103.8198],
  SK: [48.6690, 19.6990],
  SI: [46.1512, 14.9955],
  SB: [-9.6457, 160.1562],
  SO: [5.1521, 46.1996],
  ZA: [-30.5595, 22.9375],
  KR: [35.9078, 127.7669],
  SS: [6.8770, 31.3070],
  ES: [40.4637, -3.7492],
  LK: [7.8731, 80.7718],
  SD: [12.8628, 30.2176],
  SR: [3.9193, -56.0278],
  SZ: [-26.5225, 31.4659],
  SE: [60.1282, 18.6435],
  CH: [46.8182, 8.2275],
  SY: [34.8021, 38.9968],
  TW: [23.6978, 120.9605],
  TJ: [38.8610, 71.2761],
  TZ: [-6.3690, 34.8888],
  TH: [15.8700, 100.9925],
  TL: [-8.8742, 125.7275],
  TG: [8, 1.1667],
  TO: [-21.1790, -175.1982],
  TT: [10.6918, -61.2225],
  TN: [33.8869, 9.5375],
  TR: [38.9637, 35.2433],
  TM: [38.9697, 59.5563],
  TV: [-7.1095, 177.6493],
  UG: [1.3733, 32.2903],
  UA: [48.3794, 31.1656],
  AE: [23.4241, 53.8478],
  GB: [55.3781, -3.4360],
  US: [37.0902, -95.7129],
  UY: [-32.5228, -55.7658],
  UZ: [41.3110, 69.2405],
  VU: [-15.3767, 166.9592],
  VA: [41.9029, 12.4534],
  VE: [6.4238, -66.5897],
  VN: [14.0583, 108.2772],
  EH: [24.2155, -12.8858],
  YE: [15.5527, 48.5164],
  ZM: [-13.1339, 27.8493],
  ZW: [-19.0154, 29.1549],
}

export default CountryCoordinates;
