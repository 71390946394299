<script setup lang="ts" >
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';
import type {
  DnsDelegationVerificationResource,
  DomainResource,
  MtaStsSetupInstructionsResource,
} from '@/types/types.gen';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import Button from 'primevue/button';
import ShareSetupInstructions from '@/Pages/Domains/Partials/ShareSetupInstructions.vue';
import VerificationStatus from '@/Components/VerificationStatus.vue';
import VerifyButton from '@/Components/VerifyButton.vue';

defineProps<{
  domain: DomainResource | null;
  mtaStsSetupInstruction: MtaStsSetupInstructionsResource;
  verification: DnsDelegationVerificationResource;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>()

</script>

<template>
  <div>
    <div class="mb-5 flex">
      <Button
        @click="emits('close')"
        class="pb-10"
        text
        :label="$t('configurations.actions.back')"
        icon="pi pi-angle-left"
        iconPos="left"
        severity="secondary"
      />
    </div>

    <SetupInstructionsCard
      :title="$t('configurations.mta_policy.heading')"
      :description="$t('configurations.mta_policy.description')"
    >
      <div class="bg-section-foreground p-5 flex flex-col gap-3 rounded">
        <SetupInstructions
          :host="mtaStsSetupInstruction.mta_policy_cname_host"
          rd-type="CNAME"
          :value="mtaStsSetupInstruction.mta_policy"
          show-copy-button
        />
      </div>
      <template #footer>
        <div class="flex justify-between mt-2">
          <VerificationStatus :verification="verification"/>
          <div class="flex justify-between gap-4">
            <ShareSetupInstructions v-if="domain" :domain="domain"/>
            <VerifyButton
              v-if="domain"
              :verification="verification"
              :domain_uuid="domain.uuid"
              :customer_uuid="domain.customer_uuid"
            />
          </div>
        </div>
      </template>
    </SetupInstructionsCard>
  </div>
</template>
