<script setup lang="ts">
import SendersMenubar from './SendersMenubar.vue';
import { SenderReportCategory } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { SenderMenuBarLink } from '@/Pages/Statistics/Sender/types/menuBars';

const { translate } = useTranslate();
const { currentCategory } = defineProps<{ currentCategory: SenderReportCategory }>()

const menuBarCategories: SenderMenuBarLink[] = [
  [SenderReportCategory.COMPLIANCE, translate('senders_reports.menu.compliance')],
  [SenderReportCategory.DELIVERABILITY, translate('senders_reports.menu.deliverability')],
  [SenderReportCategory.REPUTATION, translate('senders_reports.menu.reputation')],
  // [SenderReportCategory.THREATS, translate('senders_reports.menu.threats')],
];

</script>
<template>
  <SendersMenubar
    :items="menuBarCategories"
    :active-item="currentCategory"
  />
</template>
