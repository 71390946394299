<script setup lang="ts">
import Tag from 'primevue/tag';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { result } = defineProps<{ result: string }>();

const { translate } = useTranslate();

const unknown = {
  severity: 'warn',
  value: translate('failure_reports.tags.result.smg_policy_action'),
};

const knownResults: Record<string, { severity: string; value: string }> = {
  'smg-policy-action': {
    severity: 'warn',
    value: translate('failure_reports.tags.result.smg_policy_action'),
  },
  'delivered': {
    severity: 'success',
    value: translate('failure_reports.tags.result.delivered'),
  },
  'spam': {
    severity: 'danger',
    value: translate('failure_reports.tags.result.spam'),
  },
  'None, Accept': {
    severity: 'secondary',
    value: translate('failure_reports.tags.result.none'),
  },
};

const tagInfo = knownResults[result] ?? unknown;
</script>

<template>
  <Tag
    class="capitalize"
    :value="tagInfo.value"
    :severity="tagInfo.severity"
  />
</template>
