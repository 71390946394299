import type { Replacements, ReplacementKey, TranslationKey, TranslationsType } from '@/Utils/Translations/types';

type Lang = keyof TranslationsType & string

class TranslatorService<Translations extends TranslationsType> {
  public static fallbackLang: Lang = 'en';

  constructor(
    private readonly translations: Translations,
    private readonly locale?: Lang,
  ) {}

  public translate<Key extends TranslationKey>(
    key: Key,
    replacements?: Replacements<Key>,
  ): string {
    if (typeof this.translations === 'undefined') {
      console.error('Translations object is not defined.');
      return key;
    }

    const lang = this.locale ?? TranslatorService.fallbackLang;

    let translation = this.getTranslation(lang, key) ?? this.getTranslation(TranslatorService.fallbackLang, key);

    if (!translation) {
      console.error(`Translation for key "${key}" in language "${lang}" not found.`);

      return key;
    }

    if (replacements) {
      Object.keys(replacements).forEach((r) => {
        const replacementValue = replacements[r as ReplacementKey<Key>];
        if (replacementValue === undefined) {
          console.warn(`Replacement value for key "${r}" is undefined.`);
        }
        translation = translation!.replace(`:${r}`, replacementValue);
      });
    }
    return translation;
  }

  private getTranslation<Key extends TranslationKey>(lang: Lang, key: Key): string | undefined {
    const translationKey = `${lang}.${key}`;

    type Result = {
      [K in Key]: Result | string;
    }

    let result = this.translations as unknown as Result;
    let translation: string | undefined = undefined;
    const keys = translationKey.split('.') as unknown as Array<keyof typeof result>;

    for (const key of keys) {
      if (!(key in result)) {
        return undefined;
      }

      const newResult = result[key] as Result;

      if (typeof newResult !== 'string') {
        result = newResult;
      } else {
        translation = newResult;
        break;
      }
    }

    return translation;
  }
}

export default TranslatorService;
