<script setup lang="ts">
import { type TimelineStatisticsResource } from '@/types/types.gen';
import AuthorisedTimelineChart from '@/Pages/Statistics/Dashboard/Charts/AuthorisedTimelineChart.vue';
import { useFormatters } from '@/Utils/Formatting';
import ChartCard from '@/Components/Cards/ChartCard.vue';

const { statistics } = defineProps<{
  statistics: TimelineStatisticsResource[];
}>();

const formatters = useFormatters();

const categorizedVolume = statistics.reduce(
  (acc: { authorised_incidents: number; unauthorised_incidents: number }, item) => {
    acc.authorised_incidents += item.total_authorised_incidents;
    acc.unauthorised_incidents += item.total_unauthorised_incidents;
    return acc;
  },
  {
    authorised_incidents: 0,
    unauthorised_incidents: 0,
  },
);
</script>

<template>
  <ChartCard :title="$t('dashboard.cards.authorised_timeline.heading')">
    <template #lead>
      <div class="text-2xl">
        {{ formatters.formatNumber(categorizedVolume.authorised_incidents, '0.0a') }}
        {{ $t('dashboard.cards.authorised_timeline.legends.approved') }}
      </div>

      <div class="mb-1.5 mt-0.5">
        {{ formatters.formatNumber(categorizedVolume.unauthorised_incidents, '0.0a') }}
        {{ $t('dashboard.cards.authorised_timeline.legends.unapproved') }}
      </div>
    </template>
    <template #graph>
      <AuthorisedTimelineChart :data="statistics"/>
    </template>
  </ChartCard>

</template>
