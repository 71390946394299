<script setup lang="ts">
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import { computed, type ComputedRef } from 'vue';
import { asPercentageVolume, makeCustomTooltip } from '@/Components/Charts/Helpers';

const { clean, blacklisted, total } = defineProps<{ clean: number; blacklisted: number; total: number }>();

const { translate } = useTranslate();

const makeTotal = () => total;
const options = makeCustomTooltip(asPercentageVolume(makeTotal));

const chartData: ComputedRef<ChartData<'doughnut'>> = computed(() => {
  return ({
    labels: [
      translate('senders_reports.senders.legends.clean'),
      translate('senders_reports.senders.legends.blacklisted'),
    ],
    datasets: [
      {
        data: [clean, blacklisted],
        backgroundColor: [
          getCssVariable('--p-graphs-positive'),
          getCssVariable('--p-graphs-negative'),
        ],
        borderWidth: 0,
      },
    ],
  });
});
</script>

<template>
  <DoughnutChart :data="chartData" :options="options" size="medium">
    <slot />
  </DoughnutChart>
</template>
