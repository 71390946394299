<script setup lang="ts">
const { iconSize = 4 } = defineProps<{
  text: string;
  iconSize?: number;
  iconClass?: string[] | string;
}>();

// We need to be explicit instead of interpolating in order for tailwind to recognise it and include the css in the page
const sizeClass = {
  'size-1': iconSize === 1,
  'size-2': iconSize === 2,
  'size-3': iconSize === 3,
  'size-4': iconSize === 4,
  'size-5': iconSize === 5,
}
</script>

<template>
  <div class="flex items-center gap-2">
    <span class="rounded-full" :class="[iconClass, sizeClass]"></span>
    <div>{{ text }}</div>
  </div>
</template>
