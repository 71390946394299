<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import Chart from '@/Components/Charts/Chart.vue';
import { getCssVariable } from '@/Utils/Styling';
import { externalTooltipHandler } from '@/Components/Charts/ChartTooltip';
import { merge } from 'lodash';

const { options } = defineProps<{
  data: ChartData<'line'>;
  options?: ChartOptions<'line'>;
}>();

const chartOptions: ChartOptions<'line'> = merge({}, {
  plugins: {
    tooltip: {
      mode: 'index',
      enabled: false,
      intersect: false,
      external: externalTooltipHandler,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  clip: false,
  scales: {
    x: {
      stacked: false,
      ticks: {
        display: true,
        color: getCssVariable('--p-form-field-color'),
      },
      grid: {
        display: true,
        color: getCssVariable('--p-content-border-color'),
      },
      border: {
        display: false,
      },
    },
    y: {
      stacked: false,
      grid: {
        display: true,
        color: getCssVariable('--p-content-border-color'),
      },
      ticks: {
        display: true,
        color: getCssVariable('--p-form-field-color'),
      },
      border: {
        display: false,
      },
    },
  },
}, options);

</script>

<template>
  <Chart type="line" :data="data" :options="chartOptions" />
</template>
