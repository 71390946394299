<script setup lang="ts">
import Accordion from 'primevue/accordion';
import { type InertiaForm, useForm } from '@inertiajs/vue3';
import { provide } from 'vue';
import PolicyPanel from '@/Pages/Domains/DmarcSettings/Partials/PolicyPanel.vue';
import RuaPanel from '@/Pages/Domains/DmarcSettings/Partials/RuaPanel.vue';
import RufPanel from '@/Pages/Domains/DmarcSettings/Partials/RufPanel.vue';
import AlignmentPanel from '@/Pages/Domains/DmarcSettings/Partials/AlignmentPanel.vue';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import SaveAndExit from '@/Components/SaveAndExit.vue';
import { useRoute } from 'ziggy-js';
import {
  type DmarcSettingsResource,
  type DnsDelegationVerificationResource,
  type DomainResource,
} from '@/types/types.gen';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import DomainsSettingsLayout from '@/Pages/Domains/DomainSettingsLayout.vue';
import LinkSetupInstructions from '@/Pages/Domains/Partials/LinkSetupInstructions.vue';
import type { SaveOrExitAction } from '@/Pages/Domains/types';
import ScoreCard from '@/Pages/Domains/Score/ScoreCard.vue';
import type { DomainScore } from '@/types/cyclops.types.gen';
import VerificationStatus from '@/Components/VerificationStatus.vue';

const { domain, dmarcSettings, verification } = defineProps<{
  domain: DomainResource;
  dmarcSettings: DmarcSettingsResource;
  cyclopsScore: DomainScore | null;
  verification: DnsDelegationVerificationResource;
}>();

const form: InertiaForm<DmarcSettingsResource> = useForm(dmarcSettings);

provide('form', form);

const submit = (action: SaveOrExitAction) => {
  const route = useRoute();

  form
    .put(
      route('ui.dmarc-settings.update', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
        action,
      }),
      responseCallbacks,
    );
};
</script>

<template>
  <DomainsSettingsLayout
    :tab="DomainSettingsTab.DMARC"
    :title="$t('dmarc_settings.title')"
    :domain="domain"
    :cyclops-score="cyclopsScore"
  >
    <div class="my-5 flex flex-row justify-between">
      <VerificationStatus :verification="verification"/>
      <LinkSetupInstructions :domain="domain" href="ui.dmarc-settings.setup"/>
    </div>
    <Accordion value="0">
      <PolicyPanel />
      <RuaPanel />
      <RufPanel />
      <AlignmentPanel />
    </Accordion>
    <template #right>
      <ScoreCard
        :score="cyclopsScore"
      >
        <template #note>
          {{ $t('scoring.note') }}
        </template>
      </ScoreCard>
    </template>
    <template #footer>
      <SaveAndExit
        :cancel-route="route('ui.domains.index', [domain.customer_uuid])"
        @submit="submit"
      />
    </template>
  </DomainsSettingsLayout>
</template>
