<script setup lang="ts">
import Button from 'primevue/button';
import type { DomainResource } from '@/types/types.gen';
import { reactive } from 'vue';
import InnerCard from '@/Components/InnerCard.vue';
import DisableBimiSettingsModel from '@/Pages/Domains/BimiSettings/Partials/DisableBimiSettingsModel.vue';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const state = reactive({
  showModal: false,
});
</script>

<template>
  <div>
    <InnerCard>
      <template #content>
        <div
          class="mb-2 text-lg font-bold"
          v-text="$t('bimi_settings.disable.heading')"
        />
        <p v-text="$t('bimi_settings.disable.message')" />
      </template>
      <template #footer>
        <Button
          @click="state.showModal = true"
          type="submit"
          class="mt-3"
          severity="secondary"
          size="small"
          :label="$t('bimi_settings.disable.action')"
          outlined
        />
      </template>
    </InnerCard>
    <DisableBimiSettingsModel
      v-if="state.showModal"
      @hide="state.showModal = false"
      :domain="domain"
    />
  </div>
</template>
