<script lang="ts" setup>
import SideBarMenuItem from '@/Layouts/Menus/SideBarMenuItem.vue';
import { usePage } from '@inertiajs/vue3';
import { useRoute } from 'ziggy-js';

const { isMobileMenuOpen = false }
  = defineProps<{ isMobileMenuOpen: boolean }>();

const { activeCustomer, activeDomain } = usePage().props;

const route = useRoute();

const activeRoute = (wildcard: string) => Boolean(route().current(wildcard));

const domainCreateRoute = route('ui.domains.create', { customer: activeCustomer.uuid });

const senderStatisticsRoute = activeDomain
  ? route('ui.senders-statistics.index', { customer: activeCustomer.uuid, domain: activeDomain!.uuid })
  : domainCreateRoute;

const failureReportsRoute = activeDomain
  ? route('ui.failure-reports.index', { customer: activeCustomer.uuid, domain: activeDomain!.uuid })
  : domainCreateRoute;

const tlsStatisticsRoute = activeDomain
  ? route('ui.tls-statistics.index', { customer: activeCustomer.uuid, domain: activeDomain!.uuid })
  : domainCreateRoute;

</script>

<template>
  <div :class="{hidden : !isMobileMenuOpen}"
     class="flex-col gap-1 p-5 bg-section-default rounded xl:bg-transparent xl:flex"
  >
    <SideBarMenuItem
      icon-class="pi pi-home"
      :title="$t('menu.home')"
      :href="route('ui.dashboard.show', { customer: activeCustomer.uuid })"
      :active="activeRoute('ui.dashboard.show')"
    />
    <div class="font-semibold py-2">
      {{ $t('menu.reports.reports') }}
    </div>

    <SideBarMenuItem
      icon-class="pi pi-chart-bar"
      :title="$t('menu.reports.email_senders')"
      :href="senderStatisticsRoute"
      :active="activeRoute('ui.senders-statistics.index')"
    />

    <SideBarMenuItem
      icon-class="pi pi-chart-line"
      :title="$t('menu.reports.tls_rpt_reports')"
      :href="tlsStatisticsRoute"
      :active="activeRoute('ui.tls-statistics.index')"
    />

    <SideBarMenuItem
      icon-class="pi pi-chart-scatter"
      :title="$t('menu.reports.failure_reports')"
      :href="failureReportsRoute"
      :active="activeRoute('ui.failure-reports.index')"
    />

    <div class="font-semibold py-2">
      {{ $t('menu.account') }}
    </div>

    <SideBarMenuItem
      icon-class="pi pi-server"
      :title="$t('menu.domains')"
      :href="route('ui.domains.index', activeCustomer.uuid)"
      :active="activeRoute('ui.domains.*') || activeRoute('*-settings.*')"
    />

    <SideBarMenuItem
      icon-class="pi pi-bell"
      :title="$t('menu.settings.alerts')"
      :href="route('ui.alerts.index', { customer: activeCustomer.uuid })"
      :active="activeRoute('ui.alerts.*')"
    />
  </div>
</template>
