<script setup lang="ts">
import DataTable, { type DataTableProps } from 'primevue/datatable';
import IconField from 'primevue/iconfield';
import Button from 'primevue/button';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';
import { reactive } from 'vue';
import { FilterMatchMode } from '@primevue/core/api';
import TableStyles from '@/Styling/Components/Table';

interface Props extends DataTableProps {
  hasSearch?: boolean;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    hasSearch: false,
    rows: 10,
    globalFilterFields: () => ['name'],
  },
);

const state = reactive({
  filters: { global: { value: null, matchMode: FilterMatchMode.CONTAINS } },
});
</script>

<template>
  <div
    :class="TableStyles.wrapper.className"
  >
    <DataTable
      v-bind="props"
      v-model:filters="state.filters"
    >
      <template #empty>{{ $t('tables.not_found') }}</template>
      <template #header v-if="hasSearch">
        <div class="flex justify-between">
          <Button
            @click="state.filters['global'].value = null"
            type="button"
            icon="pi pi-filter-slash"
            :label="$t('tables.clear')"
            outlined
          />
          <IconField>
            <InputIcon>
              <i class="pi pi-search" />
            </InputIcon>
            <InputText
              v-model="state.filters['global'].value"
              :placeholder="$t('tables.search')"
            />
          </IconField>
        </div>
      </template>
      <slot />
      <template #footer><slot name="footer"/></template>
    </DataTable>
  </div>
</template>
