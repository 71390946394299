<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import ComplianceChart from '@/Pages/Statistics/Sender/Charts/ComplianceChart.vue';
import { computed } from 'vue';

const { data } = defineProps<{ data: StatisticsTotals[] }>();

const { formatNumber, formatPercentage } = useFormatters();

const totalIncidents = computed(() => sumBy(data, 'total_incidents'));
const totalPassing = computed(() => sumBy(data, 'total_passing'));
const percentage = computed(() => {
  return totalIncidents.value > 0
    ? (totalPassing.value / totalIncidents.value) * 100
    : 0;
});

</script>
<template>
  <ChartCard
    :title="$t('senders_reports.cards.compliance')"
  >
    <template #lead>
      <h6 class="mt-4 text-2xl">{{ formatNumber(totalPassing) }}</h6>
      <span class="text-lg">
        / {{ formatNumber(totalIncidents)}}
      </span>
    </template>
    <template #graph>
      <ComplianceChart :data="data">
        <h6 class="font-bold">
          {{ formatPercentage(percentage) }}
        </h6>
        <small class="text-sm">
          {{ $t('senders_reports.senders.legends.compliant') }}
        </small>
      </ComplianceChart>
    </template>
  </ChartCard>
</template>
