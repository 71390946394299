<script setup lang="ts">
import { SenderCategory, type SenderTotalsResource } from '@/types/types.gen';
import { Link, usePage } from '@inertiajs/vue3';
import SenderTotalsChart from '@/Pages/Statistics/Dashboard/Charts/SenderTotalsChart.vue';
import ChartCard from '@/Components/Cards/ChartCard.vue';

const { totals } = defineProps<{
  totals: SenderTotalsResource[];
}>();

const { activeDomain } = usePage().props;

const aggregates = totals.reduce((acc, item) => {
  const key = item.category === SenderCategory.AUTHORISED_COMPLIANT
    ? 'approved'
    : 'other';

  acc[key] += item.total;
  return acc;
}, {
  approved: 0,
  other: 0,
});
</script>

<template>
  <ChartCard :title="$t('dashboard.cards.sender_totals.heading')">
    <template #lead>
      <div class="text-2xl">
        {{ `${aggregates.approved} ${$t('dashboard.cards.sender_totals.legends.approved')}` }}
      </div>
      <div class="mb-1.5 mt-0.5">
        {{ `${aggregates.other} ${$t('dashboard.cards.sender_totals.legends.unapproved')}` }}
      </div>

      <Link
        v-if="activeDomain?.uuid"
        :href="route('ui.senders-statistics.index', [ activeDomain.customer_uuid, activeDomain.uuid])"
        class="text-primary-default text-lg"
      >
        {{ $t('dashboard.cards.sender_totals.action')}}
      </Link>
    </template>
    <template #graph>
      <SenderTotalsChart
        :data="aggregates"
      />
    </template>
  </ChartCard>
</template>
