import { format, getLocaleFromPlatform } from 'numerable';
import { formatDate as baseFormatDate } from 'date-fns';
import * as locales from 'date-fns/locale';
import { capitalize, upperFirst } from 'lodash';
import { computed, ref } from 'vue';

export const fallbackLocale = 'en-US';
const currentLocale = ref<string>(fallbackLocale);

const getNumerableLocale = (locale: string) => getLocaleFromPlatform(locale);
const getDateFnsLocale = (locale: string) => {
  const localeForDateFns = (locale?.replace('-', '') ?? 'enUS') as keyof typeof locales;

  return locales[localeForDateFns];
};

export const useFormatters = () => {
  const numerableLocale = computed(() => getNumerableLocale(currentLocale.value));
  const dateFnsLocale = computed(() => getDateFnsLocale(currentLocale.value));

  const updateLocale = (locale: string) => currentLocale.value = locale;

  const formatNumber = (value: string | number, formatString?: string): string => {
    return format(value, formatString, { locale: numerableLocale.value });
  }

  const formatPercentage = (value: string | number, formatString?: string): string => {
    return format(value, formatString ?? '0.0', { locale: numerableLocale.value }) + '%';
  }

  const formatDate = (value: Date, formatString?: string): string => {
    return baseFormatDate(value, formatString ?? 'yyyy-MM-dd', { locale: dateFnsLocale.value });
  }

  /**
   * Turns `FOOO-bar_bAZ` into `Foo bar baz`
   */
  const sentenceCase = (value: string) => capitalize(value.replace(/[\W\s_]+/g, ' ').trim());

  /**
   * Turns `FOOO- & bar_bAZ` into `Foo & Bar Baz`
   */
  const titleCase = (value: string) => value.replace(/[^\w\s&]+|_/g, ' ').split(' ').map(upperFirst).join(' ');

  const strLimit = (value: string, limit: number, end = '...') => value.length > limit ? value.slice(0, limit - end.length) + end : value;

  return {
    updateLocale,
    formatDate,
    formatNumber,
    formatPercentage,
    titleCase,
    sentenceCase,
    strLimit,
  };
}
