<script setup lang="ts">
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import PercentageBar from '@/Components/Charts/PercentageBarChart.vue';
import type { PercentageBarItem } from '@/Pages/Statistics/Sender/types/charts';
import { computed, type ComputedRef } from 'vue';

const { translate } = useTranslate();
const { data } = defineProps<{ data: { none_total: number; quarantine_total: number; reject_total: number } }>();

const chartData: ComputedRef<PercentageBarItem[]> = computed(() => [
  {
    label: translate('ip_address_modal.overrides.legends.policy_applied_none'),
    value: data.none_total,
    colour: getCssVariable('--p-graphs-positive'),
  },
  {
    label: translate('ip_address_modal.overrides.legends.policy_applied_quarantine'),
    value: data.quarantine_total,
    colour: getCssVariable('--p-graphs-warning'),
  },
  {
    label: translate('ip_address_modal.overrides.legends.policy_applied_reject'),
    value: data.reject_total,
    colour: getCssVariable('--p-graphs-negative'),
  },
]);
</script>

<template>
  <PercentageBar :items="chartData" />
</template>
