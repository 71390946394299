<script setup lang="ts">
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import { inject } from 'vue';
import { type InertiaForm } from '@inertiajs/vue3';
import type { DmarcSettingsResource } from '@/types/types.gen';

const form: InertiaForm<DmarcSettingsResource> = inject('form')!;
</script>

<template>
  <AccordionPanel value="3">
    <AccordionHeader>
      {{ $t('dmarc_settings.alignment.heading') }}
    </AccordionHeader>
    <AccordionContent>
      <div class="flex flex-col gap-8 px-5 py-4">
        <FormGroup
          :label="$t('dmarc_settings.alignment.fields.dkim_mode.input_label')"
          :error="form.errors.dkim_alignment"
        >
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.dkim_alignment"
              v-bind="slotProps"
              :allow-empty="false"
              :options="[
                {
                  label: $t('dmarc_settings.alignment.fields.options.s'),
                  value: 's'
                },
                {
                  label: $t('dmarc_settings.alignment.fields.options.r'),
                  value: 'r'
                }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>
        <FormGroup
          :label="$t('dmarc_settings.alignment.fields.spf_mode.input_label')"
          :error="form.errors.spf_alignment"
        >
          <template v-slot:element="slotProps">
            <SelectButton
              v-model="form.spf_alignment"
              v-bind="slotProps"
              :allow-empty="false"
              :options="[
                {
                  label: $t('dmarc_settings.alignment.fields.options.s'),
                  value: 's'
                },
                {
                  label: $t('dmarc_settings.alignment.fields.options.r'),
                  value: 'r'
                }
              ]"
              optionLabel="label"
              optionValue="value"
            />
          </template>
        </FormGroup>
      </div>
    </AccordionContent>
  </AccordionPanel>
</template>
