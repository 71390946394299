<script setup lang="ts">
import type { ChartProps, ChartSelectEvent } from 'primevue/chart';
import Chart from 'primevue/chart';
import { onMounted, ref } from 'vue';
import type {
  ChartData,
  ChartOptions,
  ChartTypeRegistry,
  Chart as ChartJs,
} from 'chart.js';
/**
 * The point of this component is to wrap the primevue chart and provide the mounted hooks required to set the data and options.
 * Without those the chart components often run into errors at runtime.
 */
/** @see import('primevue/chart').ChartEmitsOptions */
defineEmits<{
  select: [ChartSelectEvent];
  loaded: [ChartJs];
}>();

const props = defineProps<ChartProps>();
const type = props.type! as keyof ChartTypeRegistry;

const chartOptions = ref<ChartOptions<typeof type>>({});
const chartData = ref<ChartData<typeof type>>({} as ChartData);

const setChartData = () => (chartData.value = props.data as ChartData);
const setChartOptions = () => (chartOptions.value = props.options ?? {});

onMounted(() => {
  setChartData();
  setChartOptions();
});
</script>

<template>
  <Chart
    v-if="Object.keys(chartData).length"
    v-bind="{...$props, ...$attrs}"
    :type="type"
    :data="chartData"
    :options="chartOptions"
  />
</template>
