<script setup lang="ts">
import FormGroup from '@/Components/FormGroup.vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { reactive, ref } from 'vue';
import type { DomainResource } from '@/types/types.gen';
import Select from 'primevue/select';
import axios from 'axios';
import { useRoute } from 'ziggy-js';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import DatePicker from 'primevue/datepicker';
import { subMonths } from 'date-fns';
import { useFormatters } from '@/Utils/Formatting';
import useCopyToClipboard from '@/Utils/useCopyToClipboard';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const emits = defineEmits<{
  (e: 'hide'): void;
}>();

const state = reactive({
  expiry: 86400,
  dateRange: [subMonths(new Date(), 7), new Date()],
  domainReportUrl: null,
});

const isVisible = ref(true);
const { copy } = useCopyToClipboard();

const generateExposureReportUrl = () => {
  const route = useRoute();
  const { formatDate } = useFormatters();

  axios
    .get(
      route('api.exposure-report.generate', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
        expiry: state.expiry,
        start_date: formatDate(state.dateRange[0], 'yyyy-LL-dd'),
        end_date: formatDate(state.dateRange[1], 'yyyy-LL-dd'),
      }),
    )
    .then((response) => {
      state.domainReportUrl = response.data.url;
    });
};

const toggleModel = () => isVisible.value = !isVisible.value;

const openDomainReportPage = () => {
  if (state.domainReportUrl) {
    window.open(state.domainReportUrl, '_blank')
  }
}

</script>

<template>
  <div>
    <slot
      name="default"
      :toggle="toggleModel"
    />

    <Dialog
      v-model:visible="isVisible"
      modal
      @hide="emits('hide')"
      :header="$t('domains.report_modal.header')"
      class="w-2/6"
    >
      <div class="flex flex-col gap-7">
        <FormGroup
          v-if="!state.domainReportUrl"
          :label="$t('domains.report_modal.fields.date_range.input_label')"
        >
          <template #element>
            <DatePicker
              selectionMode="range"
              showIcon
              v-model="state.dateRange"
              iconDisplay="input"
              hideOnRangeSelection
              pt:inputIconContainer:class="!-mt-3"
              dateFormat="yy-MM-dd"
              :fluid=true
              class="w-96"
            />
          </template>
        </FormGroup>

        <FormGroup
          v-if="!state.domainReportUrl"
          :label="$t('domains.report_modal.fields.expire_link.input_label')"
        >
          <template #element>
            <Select
              class="w-1/3"
              v-model="state.expiry"
              :options="[
                { value: 21600, text: $t('domains.report_modal.fields.expire_link.options.6_hours') },
                { value: 43200, text: $t('domains.report_modal.fields.expire_link.options.12_hours') },
                { value: 86400, text: $t('domains.report_modal.fields.expire_link.options.1_day') },
                { value: 604800, text: $t('domains.report_modal.fields.expire_link.options.1_week') },
                { value: 2592000, text: $t('domains.report_modal.fields.expire_link.options.1_month') },
                { value: 16777215, text: $t('domains.report_modal.fields.expire_link.options.never') }
              ]"
              optionLabel="text"
              optionValue="value"
            />
          </template>
        </FormGroup>

        <FormGroup
          v-if="state.domainReportUrl"
          :label="$t('domains.report_modal.fields.copy_link.input_label')"
        >
          <template #element>
            <InputGroup >
              <InputText :model-value="state.domainReportUrl" />
              <Button
                @click="copy(state.domainReportUrl)"
                icon="pi pi-copy"
                severity="secondary"
              />
            </InputGroup>
          </template>
        </FormGroup>
      </div>

      <template #footer>
        <div class="flex flex-row-reverse items-center gap-2">
          <form v-if="! state.domainReportUrl" @submit.prevent="generateExposureReportUrl" >
            <Button :label="$t('domains.report_modal.action')" type="submit" />
          </form>
          <Button
            v-else
            @click="openDomainReportPage"
            :label="$t('global.buttons.open_link')"
            icon="pi pi-arrow-up-right"
            icon-pos="right"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>
