<script setup lang="ts">
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import { useFormatters } from '@/Utils/Formatting';

const { data } = defineProps<{
  data: { approved: number; other: number };
}>();

const approvedPercentage = Math.round((data.approved / (data.approved + data.other)) * 100);

const { translate } = useTranslate();
const { formatPercentage } = useFormatters();

const chartData: ChartData<'doughnut'> = {
  labels: [
    translate('dashboard.cards.sender_totals.legends.approved'),
    translate('dashboard.cards.sender_totals.legends.unapproved'),
  ],
  datasets: [
    {
      data: [
        data.approved,
        data.other,
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-negative'),
      ],
      borderWidth: 0,
    },
  ],
};
</script>

<template>
  <DoughnutChart :data="chartData" >
    <h6 class="font-bold">
      {{ formatPercentage(approvedPercentage) }}
    </h6>
    <small class="text-sm">
      {{ $t('dashboard.cards.sender_totals.legends.approved') }}
    </small>
  </DoughnutChart>
</template>
