<script setup lang="ts">
import { router } from '@inertiajs/vue3';
import Button from 'primevue/button';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { ref } from 'vue';
import { type DnsDelegationVerificationResource } from '@/types/types.gen';
import { useRoute } from 'ziggy-js';
import { useToast } from 'primevue/usetoast';
import type { TranslationKey } from '@/Utils/Translations/types';

const { verification, customer_uuid, domain_uuid } = defineProps<{
  verification: DnsDelegationVerificationResource;
  domain_uuid: string;
  customer_uuid: string;
}>();

const toast = useToast();
const { translate } = useTranslate();

const verificationInProgress = ref(false);

const showError = (error: string) => {
  toast.add({
    severity: 'error',
    summary: error,
    detail: verification.messages.error?.map(error => translate(error as TranslationKey)).join('\n'),
    life: 5000,
  });
  verificationInProgress.value = false;
}

const verify = () => {
  verificationInProgress.value = true;
  router.post(
    useRoute()('ui.delegation-verifications.verify', [customer_uuid, domain_uuid, verification.uuid]),
    {},
    {
      onSuccess: () => {
        toast.add({
          severity: 'success',
          summary: translate('delegation-verifications.verify.success'),
          life: 5000,
        });
        verificationInProgress.value = false;
      },
      onError: (error) => {
        showError(Object.values(error)[0]);
      },
      preserveScroll: true,
    },
  );
}
</script>

<template>
  <Button
    @click="verify"
    :disabled="verificationInProgress"
    :label="$t('configurations.actions.verify')"
    severity="secondary"
  />
</template>
