<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import { computed, type ComputedRef } from 'vue';
import TopBlacklistedSendersChart from '@/Pages/Statistics/Sender/Charts/TopBlacklistedSendersChart.vue';
import Legend from '@/Components/Legend.vue';
import { countBy } from 'lodash';
import type { BlacklistedSendersCount } from '@/Pages/Statistics/Sender/types/charts';

const { groups } = defineProps<{
  groups: GroupedStatistics[];
}>();

// group all the group.blacklistings together and count ho many times a key appears across them
const blacklistings = computed(() => Object.entries(
  countBy(groups.flatMap(group => Object.keys(group.blacklistings))),
).sort((a, b) => b[1] - a[1]));

const blacklistedSenders: ComputedRef<BlacklistedSendersCount[]> = computed(() => [
  ...blacklistings.value.slice(0, 3).map(([domain, count]) => ({ domain, count })),
  {
    domain: 'Other',
    count: blacklistings.value.slice(3).reduce((acc, [_, count]) => acc + count, 0),
  },
]);

const colours: { bg: string; var: `--p-${string}` }[] = [
  { bg: 'bg-danger-darker', var: '--p-danger-darker' },
  { bg: 'bg-graphs-negative', var: '--p-graphs-negative' },
  { bg: 'bg-graphs-warning', var: '--p-graphs-warning' },
  { bg: 'bg-graphs-fill', var: '--p-graphs-fill' },
];

</script>
<template>
  <ChartCard :title="$t('senders_reports.senders.table.header.top_blacklisted_senders')">
    <template #details>
      <div class="leading-5">
        <Legend
          v-for="({domain, count}, index) in blacklistedSenders"
          :text="`${domain} (${count})`"
          :icon-size="3"
          :icon-class="colours[index].bg"
          :key="index"
        />
      </div>
    </template>
    <template #graph>
      <TopBlacklistedSendersChart class="h-44" :data="blacklistedSenders" :colours="colours" />
    </template>
  </ChartCard>
</template>
