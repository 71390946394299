<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { useFormatters } from '@/Utils/Formatting';
import { sumBy } from 'lodash';
import DeliverableVolumeChart from '@/Pages/Statistics/Sender/Charts/DeliverableVolumeChart.vue';
import { computed } from 'vue';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import Legend from '@/Components/Legend.vue';

const { formatNumber } = useFormatters();
const { dailyStatistics } = defineProps<{
  dailyStatistics: Record<string, StatisticsTotals>;
}>();

const deliverableAmount = computed(() => sumBy(Object.values(dailyStatistics), 'total_incidents'));
const totalDelivered = computed(() => sumBy(Object.values(dailyStatistics), 'total_delivered'));
const totalOverriddenAsDelivered = computed(() => sumBy(Object.values(dailyStatistics), 'total_permitted'));
</script>
<template>
  <ChartCard :title="$t('senders_reports.cards.deliverable_volume')">
    <template #lead>
      <h6 class="mt-4 text-2xl leading-none">{{ formatNumber(deliverableAmount) }}</h6>
    </template>

    <template #details>
      <div class="leading-5">
        <Legend
          :text="`${$t('senders_reports.senders.legends.overridden')} (${formatNumber(totalOverriddenAsDelivered)})`"
          :icon-size="3"
          icon-class="bg-graphs-color1"
        />
        <Legend
          :text="`${$t('senders_reports.senders.legends.delivered')} (${formatNumber(totalDelivered)})`"
          :icon-size="3"
          icon-class="bg-graphs-positive"
        />
      </div>
    </template>

    <template #graph>
      <DeliverableVolumeChart :data="dailyStatistics" />
    </template>
  </ChartCard>
</template>
