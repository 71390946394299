<script setup lang="ts">
import type { InertiaForm } from '@inertiajs/vue3';
import Checkbox from 'primevue/checkbox';
import { type AlertsResource } from '@/types/types.gen';
import { inject, reactive } from 'vue';
import { toUpper } from 'lodash';
import Button from 'primevue/button';

const form: InertiaForm<AlertsResource> = inject('form')!;

const state = reactive({
  domainTypes: [
    'dmarc',
    'spf',
    'dkim',
    'bimi',
    'tls-rpt',
    'mta-sts',
  ],
});

const emits = defineEmits<{
  (e: 'completeStep', step: string): void;
}>();

const nextStep = () => {
  emits('completeStep', 'conditions');
};

const back = () => {
  emits('completeStep', 'back-to-activity');
};

</script>
<template>
  <div class="flex flex-col gap-8 px-5 py-4">
    <div>
      <div class="font-bold mb-2">{{ $t('alerts.fields.activity_type.options.domain') }} / {{  $t(`alerts.activity.options.${form.type}.heading`) }}</div>
      <div>{{ $t(`alerts.activity.options.${form.type}.description`) }}</div>
    </div>
    <div>
      <div class="mt-4 mb-2">{{ $t('alerts.fields.select_below.input_label') }}</div>
      <div v-for="domainType of state.domainTypes" :key="domainType" class="flex items-center mb-2">
        <Checkbox v-model="form.conditions.domain_events.types" :inputId="'type_id_' + domainType" name="domain_events" :value="domainType" />
        <label
          :for="'type_id_' + domainType"
          class="ml-2"
          v-text="toUpper(domainType)"
        />
      </div>
    </div>
    <div class="mt-4">
      <div class="flex justify-between mr-5">
        <Button @click="back()" severity="secondary" :label="$t('global.buttons.back')" size="small" raised />
        <Button @click="nextStep()" severity="secondary" :label="$t('global.buttons.next')" size="small" raised />
      </div>
    </div>
  </div>
</template>
