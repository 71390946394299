<script setup lang="ts">
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import BarChart from '@/Components/Charts/BarChart.vue';
import { computed, type ComputedRef } from 'vue';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import { asPercentageVolume, makeCustomTooltip } from '@/Components/Charts/Helpers';

const { translate } = useTranslate();
const { data } = defineProps<{ data: Record<string, StatisticsTotals> }>();

const makeTotal = (date: string) => data[date].total_blocked + data[date].total_complete_failures;
const options = makeCustomTooltip(asPercentageVolume(makeTotal));

const chartData: ComputedRef<ChartData<'bar'>> = computed(() => ({
  labels: Object.keys(data),
  datasets: [
    {
      label: translate('senders_reports.senders.legends.overridden'),
      data: Object.values(data).map(stats => stats.total_blocked),
      backgroundColor: getCssVariable('--p-graphs-warning'),
      stack: 'Stack 0',
      barPercentage: 2,
      borderRadius: 3,
    },
    {
      label: translate('senders_reports.senders.legends.not_delivered'),
      data: Object.values(data).map(stats => stats.total_complete_failures),
      backgroundColor: getCssVariable('--p-graphs-negative'),
      stack: 'Stack 0',
      barPercentage: 2,
      borderRadius: 3,
    },
  ],
}));
</script>

<template>
  <BarChart :data="chartData" :options="options"/>
</template>
