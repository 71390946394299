<script setup lang="ts">
import Breadcrumb from 'primevue/breadcrumb';
import { Link } from '@inertiajs/vue3';
import type { MenuItem } from 'primevue/menuitem';
import { useFeatureRestriction } from '@/Utils/useFeatureRestriction';
import { UiRestriction } from '@/types/types.gen';

defineProps<{ model: MenuItem[] }>();

const isBreadcrumbsHidden = useFeatureRestriction(UiRestriction.NO_BREADCRUMBS);

</script>

<template>
  <Breadcrumb
    v-if="! isBreadcrumbsHidden"
    :home="{
      icon: 'pi pi-home',
      route: '/'
    }"
    :model="model"
  >
    <template #item="{ item, props }">
      <Link v-if="item.route" :href="item.route">
        <a v-bind="props.action">
          <span :class="item.icon" />
          <span>{{ item.label }}</span>
        </a>
      </Link>
      <span v-else class="p-breadcrumb-item-link" >{{ item.label }}</span>
    </template>
  </Breadcrumb>
</template>
