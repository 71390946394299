import type { HasTotals, StatisticsTotals } from '@/Pages/Statistics/Sender/types';

export const totalCompliant = (stat: HasTotals) => stat.total_incidents
  - stat.total_complete_failures;

export const totalPassing = (stat: HasTotals) => stat.total_complete_passes
  + stat.total_spf_fails_dkim_passes
  + stat.total_dkim_fails_spf_passes;

export const totalFailing = (stat: HasTotals) => stat.total_incidents
  - totalPassing(stat)
  - stat.total_forwards;

export const totalBlocked = (stat: HasTotals) => stat.total_overrides_quarantine
  + stat.total_overrides_reject;

export const totalDelivered = (stat: HasTotals) => stat.total_complete_passes
  + stat.total_spf_fails_dkim_passes
  + stat.total_dkim_fails_spf_passes
  + stat.total_overrides_none
  + stat.total_forwards;

export const totalNotDelivered = (stat: HasTotals) => stat.total_overrides_quarantine
  + stat.total_overrides_reject
  + stat.total_complete_failures;

export const getCompliancePercentage = (stats: HasTotals) => {
  const totalPassingIncidents = totalPassing(stats);
  if (totalPassingIncidents === 0) {
    return 0;
  }
  return (totalPassingIncidents / stats.total_incidents) * 100;
};
export const totalCleanIncidents = (stat: HasTotals) => stat.total_incidents - totalBlacklistedIncidents(stat);
export const totalBlacklistedIncidents = (stat: HasTotals) => 'total_blacklisted_incidents' in stat ? stat.total_blacklisted_incidents : 0;

export const createStatisticsTotals = (stat: HasTotals): StatisticsTotals => ({
  total_spf_fails_dkim_passes: stat.total_spf_fails_dkim_passes,
  total_dkim_fails_spf_passes: stat.total_dkim_fails_spf_passes,
  total_permitted: stat.total_overrides_none,
  total_blocked: totalBlocked(stat),
  total_complete_failures: stat.total_complete_failures,
  total_compliant: totalCompliant(stat),
  total_passing: totalPassing(stat),
  total_complete_passes: stat.total_complete_passes,
  total_incidents: stat.total_incidents,
  total_forwards: stat.total_forwards,
  total_failing: totalFailing(stat),
  total_delivered: totalDelivered(stat),
  total_not_delivered: totalNotDelivered(stat),
  total_blacklisted_incidents: totalBlacklistedIncidents(stat),
  total_clean_incidents: totalCleanIncidents(stat),
  total_sources: 'total_sources' in stat ? stat.total_sources : 0,
  total_blacklisted_sources: 'total_blacklisted_sources' in stat ? stat.total_blacklisted_sources : 0,
  total_isps: 'total_isps' in stat ? stat.total_isps : 0,
  total_threat_sources: 'total_threat_sources' in stat ? stat.total_threat_sources : 0,
});
