import { dt } from '@primevue/themes';
import { Chart, type ChartType, type TooltipModel } from 'chart.js';

const getOrCreateTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas?.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');

    tooltipEl.style.background = dt('tooltip.background');
    tooltipEl.style.borderRadius = dt('tooltip.border.radius');
    tooltipEl.style.color = dt('tooltip.color');
    tooltipEl.style.boxShadow = dt('tooltip.shadow');
    tooltipEl.style.opacity = '1';
    tooltipEl.style.zIndex = '99999';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'opacity .4s ease-in-out';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas?.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (context: { chart: Chart; tooltip: TooltipModel<ChartType> }) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);

    const tableHead = document.createElement('thead');

    titleLines.forEach((title: string) => {
      const tr = document.createElement('tr');
      const th = document.createElement('tr');
      th.style.whiteSpace = 'nowrap';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');

      span.style.background = colors.backgroundColor === 'rgba(0,0,0,0.1)'
        ? String(colors.borderColor)
        : String(colors.backgroundColor);

      span.style.borderColor = 'rgba(0,0,0,0.1)';
      span.style.borderRadius = '50%';
      span.style.borderWidth = '1px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';

      const td = document.createElement('td');
      td.style.whiteSpace = 'nowrap';

      const text = document.createTextNode(String(body));

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
