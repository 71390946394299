<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { useFormatters } from '@/Utils/Formatting';
import { sumBy } from 'lodash';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import { computed } from 'vue';
import ApprovedSenderIpsChart from '@/Pages/Statistics/Sender/Charts/ApprovedSenderIpsChart.vue';
import Legend from '@/Components/Legend.vue';

const { formatNumber, formatPercentage } = useFormatters();
const { groups } = defineProps<{
  groups: GroupedStatistics[];
}>();

const total = computed(() => sumBy(groups, 'total_sources'));
const blacklisted = computed(() => sumBy(groups, 'total_blacklisted_sources'));
const clean = computed(() => total.value - blacklisted.value);
</script>
<template>
  <ChartCard :title="$t('senders_reports.senders.table.header.approved_sender_ips')">
    <template #lead>
      <h6 class="mt-4 text-2xl leading-none">{{ formatNumber(total) }}</h6>
    </template>
    <template #details>
      <div class="leading-5">
        <Legend
          :text="`${$t('senders_reports.senders.legends.blacklisted')} (${formatNumber(blacklisted)})`"
          :icon-size="3"
          icon-class="bg-graphs-negative"
        />
        <Legend
          :text="`${$t('senders_reports.senders.legends.clean')} (${formatNumber(clean)})`"
          :icon-size="3"
          icon-class="bg-graphs-positive"
        />
      </div>
    </template>
    <template #graph>
      <ApprovedSenderIpsChart :clean="clean" :blacklisted="blacklisted" :total="total">
        <h6 class="font-bold">
          {{ formatPercentage(blacklisted/total * 100) }}
        </h6>
        <small class="text-sm">
          {{ $t('senders_reports.senders.legends.blacklisted') }}
        </small>
      </ApprovedSenderIpsChart>
    </template>
  </ChartCard>
</template>
