<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { useFormatters } from '@/Utils/Formatting';
import { sumBy } from 'lodash';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import VolumeChart from '@/Pages/Statistics/Sender/Charts/VolumeChart.vue';
import { computed } from 'vue';
import Legend from '@/Components/Legend.vue';

const { formatNumber } = useFormatters();
const { groups, timelineStatistics } = defineProps<{
  groups: GroupedStatistics[];
  timelineStatistics: TimelineStatisticsResource[];
}>();

const totalAmount = computed(() => sumBy(groups, 'total_incidents'));
const forwardsAmount = computed(() => sumBy(groups, 'total_forwards'));

const passingAmount = computed(() => sumBy(groups, 'total_passing'));
const failingAmount = computed(() => sumBy(groups, 'total_failing'));
</script>
<template>
  <ChartCard :title="$t('senders_reports.cards.volume')">
    <template #lead>
      <h6 class="mt-4 text-2xl leading-none">{{ formatNumber(totalAmount) }}</h6>
    </template>
    <template #details>
      <div class="leading-5">
        <Legend
          :text="`${$t('senders_reports.senders.legends.forward')} (${formatNumber(forwardsAmount)})`"
          :icon-size="3"
          icon-class="bg-graphs-fill"
        />
      <Legend
          :text="`${$t('senders_reports.senders.legends.passing_dmarc')} (${formatNumber(passingAmount)})`"
          :icon-size="3"
          icon-class="bg-graphs-positive"
        />
      <Legend
          :text="`${$t('senders_reports.senders.legends.failing_dmarc')} (${formatNumber(failingAmount)})`"
          :icon-size="3"
          icon-class="bg-graphs-negative"
        />
      </div>
    </template>
    <template #graph>
      <VolumeChart :data="timelineStatistics" />
    </template>
  </ChartCard>
</template>
