<script setup lang="ts">
import { useFormatters } from '@/Utils/Formatting';
import { fromUnixTime } from 'date-fns';
import type { BimiSettingResource } from '@/types/types.gen';
import InnerCard from '@/Components/InnerCard.vue';

defineProps<{
  bimiSettings: BimiSettingResource;
}>();

const formatters = useFormatters();

const formatDate = (date: number) => formatters.formatDate(fromUnixTime(date));
</script>

<template>
  <InnerCard>
    <template #content>
      <div class="flex flex-col gap-2">
        <div class="flex flex-col gap-1 md:flex-row md:gap-8">
          <div class="flex justify-between font-bold md:w-1/6"
               v-text="$t('bimi_settings.fields.certificate.url')"
          />
          <div class="flex flex-1 justify-between"
               v-text="bimiSettings.certificate_path"
          />
        </div>

        <div class="flex flex-col gap-1 md:flex-row md:gap-8">
          <div class="flex justify-between font-bold md:w-1/6"
               v-text="$t('bimi_settings.fields.certificate.cn')"
          />
          <div class="flex flex-1 justify-between"
               v-text="bimiSettings.certificate_x509?.issuer?.CN"
          />
        </div>

        <div class="flex flex-col gap-1 md:flex-row md:gap-8">
          <div class="flex justify-between font-bold md:w-1/6"
               v-text="$t('bimi_settings.fields.certificate.expiry')"
          />
          <div class="flex flex-1 justify-between"
               v-text="formatDate(bimiSettings.certificate_x509?.validTo_time_t ?? 0)"
          />
        </div>
      </div>
    </template>
  </InnerCard>
</template>
