<script setup lang="ts">
import { type AlertsResource, type CustomerResource } from '@/types/types.gen';
import AppLayout from '@/Layouts/AppLayout.vue';
import { type InertiaForm, useForm } from '@inertiajs/vue3';

import { provide, ref } from 'vue';
import AlertForm from '@/Pages/Alerts/Partials/AlertForm.vue';
import { useRoute } from 'ziggy-js';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import NavCrumbs from '@/Components/NavCrumbs.vue';

const { alert, customer } = defineProps<{
  alert: AlertsResource;
  customer: CustomerResource;
}>();

const form: InertiaForm<AlertsResource> = useForm(alert);
provide('form', form);

const submit = () => {
  const route = useRoute();

  form.put(
    route('ui.alerts.update', {
      customer: customer.uuid,
      alert: alert.uuid,
    }),
    responseCallbacks,
  );
};

const { translate } = useTranslate();

const items = ref([
  { label: translate('alerts.listing.title'), route: useRoute()('ui.alerts.index', customer.uuid) },
  { label: translate('alerts.edit.title') },
]);

</script>

<template>
  <AppLayout :title="$t('alerts.edit.title')">
    <NavCrumbs :model="items" />
    <AlertForm @submit="submit" :customer="customer" :alert="alert"/>
  </AppLayout>
</template>
