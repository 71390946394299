<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { useFormatters } from '@/Utils/Formatting';
import { sumBy } from 'lodash';
import type { GroupedStatistics, StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import { computed } from 'vue';
import ApprovedSenderVolumeChart from '@/Pages/Statistics/Sender/Charts/ApprovedSenderVolumeChart.vue';
import Legend from '@/Components/Legend.vue';

const { formatNumber } = useFormatters();
const { groups, dailyStatistics } = defineProps<{
  groups: GroupedStatistics[];
  dailyStatistics: Record<string, StatisticsTotals>;
}>();

const compliantAmount = computed(() => sumBy(groups, 'total_incidents'));
const clean = computed(() => sumBy(Object.values(dailyStatistics), 'total_clean_incidents'));
const blacklisted = computed(() => sumBy(Object.values(dailyStatistics), 'total_blacklisted_incidents'));
</script>
<template>
  <ChartCard :title="$t('senders_reports.senders.table.header.approved_sender_volume')">
    <template #details>
      <div class="leading-5">
        <Legend
          :text="`${$t('senders_reports.senders.legends.blacklisted')} (${formatNumber(blacklisted)})`"
          :icon-size="3"
          icon-class="bg-graphs-negative"
        />
        <Legend
          :text="`${$t('senders_reports.senders.legends.clean')} (${formatNumber(clean)})`"
          :icon-size="3"
          icon-class="bg-graphs-positive"
        />
      </div>
    </template>
    <template #lead>
      <h6 class="mt-4 text-2xl leading-none">{{ formatNumber(compliantAmount) }}</h6>
    </template>
    <template #graph>
      <ApprovedSenderVolumeChart :data="dailyStatistics"/>
    </template>
  </ChartCard>
</template>
