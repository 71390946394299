<script setup lang="ts">
import { type AlertsResource } from '@/types/types.gen';
import { inject } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3';
import SelectButton from 'primevue/selectbutton';
import FormGroup from '@/Components/FormGroup.vue';
import Button from 'primevue/button';

const emits = defineEmits<{
  (e: 'completeStep', step: string): void;
}>();

const form: InertiaForm<AlertsResource> = inject('form')!;

const nextStep = () => {
  emits('completeStep', 'monitor');
};

</script>
<template>
  <div>
    <div>
      <FormGroup :label="$t('alerts.monitor.heading')">
        <template #element>
          <SelectButton
            v-model="form.scope"
            :allow-empty="false"
            :options="[
                { label: $t('alerts.fields.scope.options.system'), value: 'system' },
                { label: $t('alerts.fields.scope.options.partner'), value: 'partner' },
                { label: $t('alerts.fields.scope.options.customer'), value: 'customer' },
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </FormGroup>
    </div>
    <div class="mt-4">
      <div class="flex justify-end mr-5">
        <Button @click="nextStep()" severity="secondary" :label="$t('global.buttons.next')" size="small" raised />
      </div>
    </div>

  </div>
</template>
