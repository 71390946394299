<script setup lang="ts">
import Edit from '@/Pages/Domains/DkimSettings/Edit.vue';
import Drawer from '@/Pages/Domains/DkimPublicKeys/Partials/Drawer.vue';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import { type InertiaForm, useForm } from '@inertiajs/vue3';
import { provide } from 'vue';
import { useRoute } from 'ziggy-js';
import type { PaginatedResponse } from '@/types/http';
import type {
  DkimPublicKeyResource,
  DkimSettingsResource,
  DomainResource,
} from '@/types/types.gen';
import type { DomainScore } from '@/types/cyclops.types.gen';

const { domain, dkimPublicKey } = defineProps<{
  dkimPublicKeys: PaginatedResponse<DkimPublicKeyResource>;
  dkimSettings: DkimSettingsResource;
  dkimPublicKey: DkimPublicKeyResource;
  domain: DomainResource;
  cyclopsScore: DomainScore | null;
}>();

const form: InertiaForm<DkimPublicKeyResource> = useForm(dkimPublicKey);

provide('form', form);

const submit = () => {
  const route = useRoute();
  form.put(
    route('ui.dkim-public-keys.update', {
      customer: domain.customer_uuid,
      domain: domain.uuid,
      dkim_public_key: dkimPublicKey.uuid,
    }),
    responseCallbacks,
  );
};
</script>

<template>
  <Edit
    :domain="domain"
    :dkim-public-keys="dkimPublicKeys"
    :dkim-settings="dkimSettings"
    :cyclops-score="cyclopsScore"
  />
  <Drawer
    :domain="domain"
    @submit="submit"
    :header="$t('dkim_settings.edit_drawer.header')"
  />
</template>
