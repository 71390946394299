<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { DomainResource } from '@/types/types.gen';
import Tag from 'primevue/tag';

const { domain } = defineProps<{ domain: DomainResource }>();

const { translate } = useTranslate()

const domainStatus = (() => {
  if (!domain.dmarc_verification_result || domain.dmarc_verification_result === 'error') {
    return {
      value: translate('domains.tags.status.not_verified.value'),
      severity: 'danger',
      tooltip: translate('domains.tags.status.not_verified.tooltip'),
    }
  }

  if (domain.first_report_date) {
    return {
      value: translate('domains.tags.status.reporting.value'),
      severity: domain.dmarc_verification_result === 'partial' ? 'info' : 'success',
      tooltip: domain.dmarc_verification_result === 'partial'
        ? translate('domains.tags.status.reporting.tooltip_partial')
        : translate('domains.tags.status.reporting.tooltip'),
    }
  }
  return {
    value: translate('domains.tags.status.not_reporting.value'),
    severity: 'warn',
    tooltip: domain.dmarc_verification_result === 'partial'
      ? translate('domains.tags.status.not_reporting.tooltip_partial')
      : translate('domains.tags.status.not_reporting.tooltip'),
  }
})();

</script>

<template>
  <Tag
    v-if="domain.dmarc_verification_result === 'partial'"
    class="cursor-default"
    :severity="domainStatus.severity"
    v-tooltip.top="domainStatus.tooltip"
    :value="domainStatus.value"
    icon='pi pi-info-circle'
  />
  <Tag
    v-else
    class="cursor-default"
    :severity="domainStatus.severity"
    v-tooltip.top="domainStatus.tooltip"
    :value="domainStatus.value"
  />
</template>
