<script setup lang="ts">
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import BarChart from '@/Components/Charts/BarChart.vue';

const { translate } = useTranslate();
const { data } = defineProps<{ data: TimelineStatisticsResource[] }>();

const chartData: ChartData<'bar'> = {
  labels: data.map(stats => stats.date),
  datasets: [
    {
      label: translate('dashboard.cards.authorised_timeline.legends.approved'),
      data: data.map(stats => stats.total_authorised_incidents),
      backgroundColor: getCssVariable('--p-graphs-positive'),
      stack: 'Stack 0',
      barPercentage: 2,
      borderRadius: 3,
    },
    {
      label: translate('dashboard.cards.authorised_timeline.legends.unapproved'),
      data: data.map(stats => stats.total_unauthorised_incidents),
      backgroundColor: getCssVariable('--p-graphs-negative'),
      stack: 'Stack 0',
      barPercentage: 2,
      borderRadius: 3,
    },
  ],
};
</script>

<template>
  <BarChart :data="chartData" />
</template>
