<script setup lang="ts">
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import BarChart from '@/Components/Charts/BarChart.vue';
import { DailyStatisticsAggregator } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';
import { computed, type ComputedRef } from 'vue';

const { translate } = useTranslate();
const { data } = defineProps<{ data: TimelineStatisticsResource[] }>();

const dailyStatistics = new DailyStatisticsAggregator(data).groupedData;

const chartData: ComputedRef<ChartData<'bar'>> = computed(() => ({
  labels: Object.keys(dailyStatistics),
  datasets: [
    {
      label: translate('senders_reports.senders.legends.forward'),
      data: Object.values(dailyStatistics).map(stats => stats.total_forwards),
      backgroundColor: getCssVariable('--p-graphs-fill'),
      stack: 'Stack 0',
      barPercentage: 2.7,
      borderRadius: 3,
    },
    {
      label: translate('senders_reports.senders.legends.passing_dmarc'),
      data: Object.values(dailyStatistics).map(stats => stats.total_passing),
      backgroundColor: getCssVariable('--p-graphs-positive'),
      stack: 'Stack 0',
      barPercentage: 2.7,
      borderRadius: 3,
    },
    {
      label: translate('senders_reports.senders.legends.failing_dmarc'),
      data: Object.values(dailyStatistics).map(stats => stats.total_failing),
      backgroundColor: getCssVariable('--p-graphs-negative'),
      stack: 'Stack 0',
      barPercentage: 2.7,
      borderRadius: 3,
    },
  ],
}));
</script>

<template>
  <BarChart :data="chartData" />
</template>
