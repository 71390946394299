<script setup lang="ts">
import AppContainer from '@/Layouts/Partials/AppContainer.vue';
import { Head } from '@inertiajs/vue3';
import Toast from 'primevue/toast';
import TopMenu from '@/Layouts/Menus/TopMenu.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import { useFeatureRestriction } from '@/Utils/useFeatureRestriction';
import { UiRestriction } from '@/types/types.gen';
import SideBar from '@/Layouts/Partials/SideBar.vue';

const { withMenu = true } = defineProps<{
  title: string;
  withMenu?: boolean;
}>();

const isFixedWidth = useFeatureRestriction(UiRestriction.FIXED_WIDTH);
const isTopMenuHidden = useFeatureRestriction(UiRestriction.NO_TOP_MENU);
const hasSideBar = useFeatureRestriction(UiRestriction.HAS_SIDEBAR);

const needsMenu = withMenu && !isTopMenuHidden;
</script>

<template>
  <Head :title="title" />
  <Toast position="top-center" />
  <ConfirmDialog />
  <AppContainer>
    <SideBar v-if="hasSideBar" />
    <div
      :class="{'max-w-screen-2xl': isFixedWidth}"
      class="flex w-full flex-col mx-auto">
      <TopMenu v-if="needsMenu" />
      <slot />
    </div>
  </AppContainer>
</template>
