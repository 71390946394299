<script setup lang="ts">
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';
import FormGroup from '@/Components/FormGroup.vue';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { type InertiaForm, router } from '@inertiajs/vue3';
import { inject, ref } from 'vue';
import type { DkimPublicKeyResource, DomainResource } from '@/types/types.gen';
import Drawer from 'primevue/drawer';
import { useRoute } from 'ziggy-js';
import DeleteConfirmButton from '@/Components/DeleteConfirmButton.vue';

const { domain } = defineProps<{
  domain: DomainResource;
  header: string;
}>();

const emits = defineEmits(['submit']);

const form: InertiaForm<DkimPublicKeyResource> = inject('form')!;

const visible = ref(true);

const deletePublicKey = () => {
  router.delete(
    useRoute()('ui.dkim-public-keys.destroy', {
      customer: domain.customer_uuid,
      domain: domain.uuid,
      dkim_public_key: form.uuid,
    }),
  );
};
</script>

<template>
  <Drawer
    v-model:visible="visible"
    @hide="
      router.get(
        route('ui.dkim-settings.edit', {
          customer: domain.customer_uuid,
          domain: domain.uuid
        })
      )
    "
    :header="header"
    position="right"
    closeIcon="pi pi-sign-out"
    :pt="{
      root: {
        class: '!max-w-2xl !w-full !h-screen rounded'
      }
    }"
  >
    <div class="flex flex-col gap-8">
      <FormGroup
        :label="$t('dkim_settings.fields.selector.input_label')"
        :error="form.errors.selector"
      >
        <template v-slot:element="slotProps">
          <InputText
            :disabled="!!form.uuid"
            v-bind="slotProps"
            v-model="form.selector"
          />
        </template>
      </FormGroup>

      <FormGroup
        :label="$t('dkim_settings.fields.record_type.input_label')"
        :error="form.errors.record_type"
      >
        <template v-slot:element="slotProps">
          <SelectButton
            v-model="form.record_type"
            v-bind="slotProps"
            :disabled="!!form.uuid"
            :allow-empty="false"
            :options="[
              { label: 'CNAME', value: 'cname' },
              { label: 'TXT', value: 'txt' }
            ]"
            optionLabel="label"
            optionValue="value"
          />
        </template>
      </FormGroup>

      <FormGroup
        :label="$t('dkim_settings.fields.record_value.input_label')"
        :error="form.errors.value"
        :example="$t('dkim_settings.fields.record_value.example')"
      >
        <template v-slot:element="slotProps">
          <Textarea
            v-bind="slotProps"
            v-model="form.value"
            rows="5"
            cols="30"
          />
        </template>
      </FormGroup>

      <FormGroup label="TTL" :error="form.errors.ttl">
        <template v-slot:element="slotProps">
          <InputText v-bind="slotProps" v-model="form.ttl" />
        </template>
      </FormGroup>

      <FormGroup
        :label="$t('dkim_settings.fields.description.input_label')"
        :error="form.errors.description"
      >
        <template v-slot:element="slotProps">
          <Textarea v-bind="slotProps" v-model="form.description" rows="5" cols="30"/>
        </template>
      </FormGroup>
    </div>
    <template #footer>
      <div class="flex items-center justify-between gap-2">
        <div>
          <DeleteConfirmButton v-if="form.uuid" @delete="deletePublicKey" />
        </div>
        <form @submit.prevent="emits('submit')">
          <Button :label="$t('global.buttons.save')" type="submit" />
        </form>
      </div>
    </template>
  </Drawer>
</template>
