import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { App, Reactive } from 'vue';
import { type Page } from '@inertiajs/core';
import { watch } from 'vue';
import type { TranslationsType } from '@/Utils/Translations/types';
import TranslatorService from '@/Utils/Translations/TranslatorService';

export const TranslatorPlugin = {
  install(app: App, page: Reactive<Page>) {
    let lang: keyof TranslationsType = TranslatorService.fallbackLang;

    if (page.props.auth?.user) {
      const { language: { code } } = page.props.auth.user;
      lang = code;
    }

    const { translate, updateLanguage } = useTranslate();
    updateLanguage(lang);
    app.config.globalProperties.$t = translate;

    watch(page, (newPage: Page) => updateLanguage(newPage.props.auth.user?.language.code ?? TranslatorService.fallbackLang));
  },
};
