import './bootstrap';
import '../css/app.css';

import { createApp, h, type DefineComponent, reactive, ref } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import ConfirmationService from 'primevue/confirmationservice';
import { ZiggyVue, type Config } from 'ziggy-js';
import { Ziggy } from '@/ziggy';
import { TranslatorPlugin } from '@/Utils/Translations/TranslationPlugin';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';
import PrimeVue from 'primevue/config';
import getTheme from '@/Themes/getTheme';
import { FormattingPlugin } from '@/Utils/Formatting/FormattingPlugin';

const appName = import.meta.env.VITE_APP_NAME || 'Sendmarc';
export const unMount = ref<() => void | undefined>();
export const app = ref<ReturnType<typeof createApp>>();

export default () => createInertiaApp({
  title: title => `${title} - ${appName}`,
  resolve: (name) => {
    const pages = import.meta.glob<DefineComponent>('./Pages/**/*.vue', { eager: true });
    return pages[`./Pages/${name}.vue`];
  },
  setup({ el, App, props, plugin }) {
    const initialPage = reactive(props.initialPage);
    Ziggy.url = initialPage.props.oemConfig.url;
    window.Ziggy = Ziggy;

    app.value = createApp({
      render: () => h(App, props),
      setup() {},
    })
      .directive('tooltip', Tooltip)
      .directive('ripple', Ripple)
      .use(ConfirmationService)
      .use(ToastService)
      .use(plugin)
      .use(ZiggyVue, { ...Ziggy, url: initialPage.props.oemConfig.url } as Config)
      .use(PrimeVue, {
        theme: getTheme(initialPage.props.oemConfig.theme),
      })
      .use(TranslatorPlugin, initialPage)
      .use(FormattingPlugin, initialPage);

    app.value.mount(el);
    unMount.value = () => app.value!.unmount.call(app);
  },
  progress: {
    color: 'var(--p-primary-color)',
    showSpinner: true,
  },
});
