<script setup lang="ts">
import FormGroup from '@/Components/FormGroup.vue';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import Select from 'primevue/select';
import EmailsInput from '@/Components/EmailsListBox.vue';
import AccordionPanel from 'primevue/accordionpanel';
import { inject } from 'vue';
import { type InertiaForm } from '@inertiajs/vue3';
import type { DmarcSettingsResource } from '@/types/types.gen';

const form: InertiaForm<DmarcSettingsResource> = inject('form')!;
</script>

<template>
  <AccordionPanel value="1">
    <AccordionHeader>
      {{ $t('dmarc_settings.rua.heading') }}
    </AccordionHeader>
    <AccordionContent value="1">
      <div class="flex flex-col gap-8 px-5 py-4">
        <FormGroup
          :label="$t('dmarc_settings.rua.fields.interval.input_label')"
          :error="form.errors.rua_interval"
        >
          <template v-slot:element="slotProps">
            <Select
              class="w-full xl:w-1/3"
              v-model="form.rua_interval"
              v-bind="slotProps"
              :options="[
                {
                  name: $t('dmarc_settings.rua.fields.interval.options.1_day'),
                  value: 86400
                },
                {
                  name: $t('dmarc_settings.rua.fields.interval.options.6_hours'),
                  value: 21600
                },
                {
                  name: $t('dmarc_settings.rua.fields.interval.options.4_hours'),
                  value: 14400
                },
                {
                  name: $t('dmarc_settings.rua.fields.interval.options.2_hours'),
                  value: 7200
                },
                {
                  name: $t('dmarc_settings.rua.fields.interval.options.1_hour'),
                  value: 3600
                }
              ]"
              optionLabel="name"
              optionValue="value"
            />
          </template>
        </FormGroup>
        <FormGroup
          :label="$t('dmarc_settings.rua.fields.emails.input_label')"
          :error="form.errors.rua_emails"
        >
          <template v-slot:element="slotProps">
            <EmailsInput
              :emails="form.rua_emails ?? []"
              class="w-full xl:w-1/3"
              v-bind="slotProps"
            />
          </template>
        </FormGroup>
      </div>
    </AccordionContent>
  </AccordionPanel>
</template>
