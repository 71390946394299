<script setup lang="ts">
import { getCssVariable } from '@/Utils/Styling';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import PercentageBar from '@/Components/Charts/PercentageBarChart.vue';
import type { PercentageBarItem } from '@/Pages/Statistics/Sender/types/charts';
import { computed, type ComputedRef } from 'vue';
import { asPercentageVolume, makeCustomTooltip } from '@/Components/Charts/Helpers';

const { translate } = useTranslate();
const { data } = defineProps<{ data: { total_passing: number; total_forwards: number; total_failing: number } }>();

const makeTotal = () => data.total_passing + data.total_failing + data.total_forwards;
const options = makeCustomTooltip(asPercentageVolume(makeTotal));

const chartData: ComputedRef<PercentageBarItem[]> = computed(() => [
  {
    label: translate('senders_reports.senders.legends.passing_dmarc'),
    value: data.total_passing,
    colour: getCssVariable('--p-graphs-positive'),
  },
  {
    label: translate('senders_reports.senders.legends.forward'),
    value: data.total_forwards,
    colour: getCssVariable('--p-graphs-fill'),
  },
  {
    label: translate('senders_reports.senders.legends.failing_dmarc'),
    value: data.total_failing,
    colour: getCssVariable('--p-graphs-negative'),
  },
]);
</script>

<template>
  <PercentageBar :items="chartData" :options="options" />
</template>
