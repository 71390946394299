<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import Chart from '@/Components/Charts/Chart.vue';
import type { DoughnutChartSize } from '@/Components/types/types';
import { externalTooltipHandler } from '@/Components/Charts/ChartTooltip';
import { merge } from 'lodash';

const { size, animationDuration, data, options } = defineProps<{
  data: ChartData<'doughnut'>;
  options?: ChartOptions<'doughnut'>;
  size?: DoughnutChartSize;
  animationDuration?: number;
}>();

const config = {
  small: {
    cutout: 23,
    classes: 'max-w-[65px] max-h-[65px] min-h-[65px]',
  },
  medium: {
    cutout: 40,
    classes: 'max-w-32 max-h-32 min-h-32',
  },
}[size || 'medium'];

const chartOptions: ChartOptions<'doughnut'> = merge({}, {
  cutout: config.cutout,
  animation: {
    duration: animationDuration ?? 1000,
  },
  elements: {
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      external: externalTooltipHandler,
    },
  },
}, options);
</script>

<template>
  <div :class="config.classes">
    <div class="relative" >
      <Chart
        class="z-10"
        type="doughnut"
        :data="data"
        :options="chartOptions"
        :canvasProps="{'class':'mx-auto w-full'}"
      />
      <span class="absolute z-0 inset-0 flex flex-col items-center justify-center text-center">
        <slot />
      </span>
    </div>
  </div>
</template>
