<script setup lang="ts">
import type {
  BrandingResource,
  CountryTotalsResource,
  DomainResource,
  DomainSenderStatisticsResource,
  PartnerResource,
  TimelineStatisticsResource,
} from '@/types/types.gen';
import type { DomainScore } from '@/types/cyclops.types.gen';
import ExposureReportLayout from '@/Pages/Domains/Exposure/ExposureReportLayout.vue';
import ScoreCard from '@/Pages/Domains/Score/ScoreCard.vue';
import Card from 'primevue/card';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';

import ExposureVolumeCard from '@/Pages/Domains/Exposure/Partials/ExposureVolumeCard.vue';
import type { DateRange, SenderComplianceStatistics, StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import ComplianceCard from '@/Pages/Statistics/Sender/Partials/ChartCards/ComplianceCard.vue';
import { createStatisticsTotals } from '@/Pages/Statistics/Sender/Helpers/TimelineStatisticsHelpers';
import DeliverabilityCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverabilityCard.vue';
import DeliverableVolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverableVolumeCard.vue';
import DomainSendersCard from '@/Pages/Statistics/Dashboard/Partials/SendersComplianceCard.vue';
import SenderComplianceCard from '@/Pages/Domains/Exposure/Partials/SenderComplianceCard.vue';
import CountryTotalsCard from '@/Pages/Statistics/Dashboard/Charts/CountryTotalsCard.vue';
import { computed, type ComputedRef, provide } from 'vue';
import { DailyStatisticsAggregator } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';

const { dailyVolume, branding } = defineProps<{
  countryTotals: CountryTotalsResource[];
  dateRange: DateRange;
  domain: DomainResource;
  domainSenders: DomainSenderStatisticsResource[];
  partner: PartnerResource;
  cyclopsScore: DomainScore;
  dailyVolume: TimelineStatisticsResource[];
  senderComplianceStatistics: SenderComplianceStatistics;
  branding: BrandingResource;
  insights: { exposure: string[]; recommendations: string[] };
}>()

const dailyStatistics = computed(() => new DailyStatisticsAggregator(dailyVolume).groupedData);
const totals: ComputedRef<StatisticsTotals[]> = computed(
  () => dailyVolume.map(dailyVolume => createStatisticsTotals(dailyVolume)),
);

provide('branding', branding);

</script>

<template>
  <ExposureReportLayout :partner="partner" :domain="domain">
    <div class="flex gap-8">
      <div class="min-w-80 max-w-96">
        <ScoreCard
          class="w-full"
          :score="cyclopsScore"
        />
        <div class="mt-8 italic text-sm">
          {{ $t('exposure_report.scoring.note') }}
        </div>
      </div>

      <div class="w-full flex flex-col gap-8">
        <ExposureVolumeCard
          :date-range="dateRange"
          :volume="dailyVolume"
        />

        <div class="flex gap-8">
          <ComplianceCard
            :data="totals"
          />

          <DeliverabilityCard
            :data="totals"
          />

          <DeliverableVolumeCard
            :daily-statistics="dailyStatistics"
          />
        </div>

        <DomainSendersCard
          :title="$t('exposure_report.top_senders.heading')"
          :statistics="domainSenders"
        />

        <div class="flex gap-8 justify-between">
          <CountryTotalsCard
            class="w-full"
            :country-totals="countryTotals"
          />
          <div class="w-full">
            <SenderComplianceCard
              class="w-full"
              :data="senderComplianceStatistics"
            />

            <div class="flex flex-col gap-4 italic p-10">
              <div>{{ $t('exposure_report.sender_readiness.legends.configured.description') }}</div>
              <div>{{ $t('exposure_report.sender_readiness.legends.partially.description') }}</div>
              <div>{{ $t('exposure_report.sender_readiness.legends.unconfigured.description') }}</div>
            </div>

          </div>
        </div>

        <Card class="w-full">
          <template #content>
            <div
              class="font-semibold text-lg"
              v-text="$t('exposure_report.risk_analysis.heading')"
            />
            <DataTable :value="insights.exposure?.map(risk => ({risk}))">
                <Column>
                  <template #body= {data}>
                    {{ data.risk }}
                  </template>
                </Column>
            </DataTable>
          </template>
        </Card>

        <Card class="w-full">
          <template #content>
            <div
              class="font-semibold text-lg"
              v-text="$t('exposure_report.recommendations.heading')"
            />
            <DataTable :value="insights.recommendations?.map(recommendation => ({recommendation}))">
              <Column>
                <template #body= {data}>
                  {{ data.recommendation }}
                </template>
              </Column>
            </DataTable>
          </template>
        </Card>

      </div>
    </div>
  </ExposureReportLayout>
</template>
