<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { sumBy } from 'lodash';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import type { DoughnutChartSize } from '@/Components/types/types';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import { computed, type ComputedRef } from 'vue';
import { asPercentageVolume, makeCustomTooltip } from '@/Components/Charts/Helpers';

const { data } = defineProps<{
  data: StatisticsTotals[];
  size?: DoughnutChartSize;
}>();
const { translate } = useTranslate();

const makeTotal = () => sumBy(data, datum => datum.total_passing + datum.total_failing + datum.total_forwards);
const options = makeCustomTooltip(asPercentageVolume(makeTotal));

const chartData: ComputedRef<ChartData<'doughnut'>> = computed(() => ({
  labels: [
    translate('senders_reports.senders.legends.passing_dmarc'),
    translate('senders_reports.senders.legends.failing_dmarc'),
    translate('senders_reports.senders.legends.forward'),
  ],
  datasets: [
    {
      data: [
        sumBy(data, 'total_passing'),
        sumBy(data, 'total_failing'),
        sumBy(data, 'total_forwards'),
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-negative'),
        getCssVariable('--p-graphs-fill'),
      ],
      borderWidth: 0,
    },
  ],
}));
</script>
<template>
  <DoughnutChart :size="size" :options="options" :data="chartData">
    <slot />
  </DoughnutChart>
</template>
