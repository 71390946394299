<script setup lang="ts">
import Button from 'primevue/button';
import type { DomainResource } from '@/types/types.gen';
import { reactive } from 'vue';
import EnableBimiSettingsModal from '@/Pages/Domains/BimiSettings/Partials/EnableBimiSettingsModal.vue';
import InnerCard from '@/Components/InnerCard.vue';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const state = reactive({
  showModal: false,
});
</script>

<template>
  <InnerCard class="mt-8">
    <template #content>
      <div
        class="mb-2 text-lg font-bold"
        v-text="$t('bimi_settings.enable.heading')"
      />
      <p v-text="$t('bimi_settings.enable.message')" />
    </template>
    <template #footer>
      <Button
        @click="state.showModal = true"
        type="submit"
        class="mt-3"
        severity="secondary"
        :label="$t('bimi_settings.enable.action')"
      />
    </template>
  </InnerCard>

  <div class="mt-8">
    <div class="text-lg font-bold" v-text="$t('bimi_settings.info.heading')" />
    <div class="mt-2">{{ $t('bimi_settings.info.message') }}</div>
  </div>

  <EnableBimiSettingsModal
    v-if="state.showModal"
    @hide="state.showModal = false"
    :domain="domain"
  />
</template>
