import { useTranslate } from '@/Utils/Translations/useTranslate';
// @ts-expect-error Genuinely cannot get TS to recognise the declaration in global.d.ts
import toasteventbus from 'primevue/toasteventbus';

export default function () {
  const { translate } = useTranslate();

  const copy = (text: string) => {
    navigator.clipboard.writeText(text);

    toasteventbus.emit('add', {
      severity: 'success',
      life: 3000,
      summary: translate('global.copied'),
    });
  };

  return {
    copy,
  };
}
