<script setup lang="ts">
import Card from 'primevue/card';
import { useFormatters } from '@/Utils/Formatting';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import DeliverabilityPercentageBar from '@/Pages/Statistics/Sender/Charts/DeliverabilityPercentageBar.vue';
import type { SenderIpAddressStatisticsResource } from '@/types/types.gen';
import { createStatisticsTotals } from '@/Pages/Statistics/Sender/Helpers/TimelineStatisticsHelpers';
import { computed } from 'vue';

const { formatNumber } = useFormatters();

const { senderIpAddress } = defineProps<{
  senderIpAddress: SenderIpAddressStatisticsResource;
}>();

const statisticsTotals = computed(() => createStatisticsTotals(senderIpAddress));
</script>
<template>
  <div
      class="flex flex-col gap-8"
      v-if="senderIpAddress"
    >
      <div class="flex gap-8 justify-between">

        <Card class="w-full">
          <template #title>
            {{ $t('ip_address_modal.overview.cards.volume') }}
          </template>
          <template #content>
            <div class="text-lg">
              {{ formatNumber(senderIpAddress.total_incidents) }}
            </div>
          </template>
        </Card>

        <Card class="w-full">
          <template #title>
            {{ $t('ip_address_modal.overview.cards.compliance') }}
          </template>
          <template #content>
            <CompliancePercentageBar
              :data="statisticsTotals"
            />
          </template>
        </Card>

        <Card class="w-full">
          <template #title>
            {{ $t('ip_address_modal.overview.cards.deliverability') }}
          </template>
          <template #content>
            <DeliverabilityPercentageBar
              :data="statisticsTotals"
            />
          </template>
        </Card>

      </div>
      <Card class="w-full">
        <template #content>
          <div class="grid grid-cols-1 gap-4">
            <div>
              <b> {{ $t('ip_address_modal.overview.properties.host') }} </b>
              <div> {{ senderIpAddress.host }} </div>
            </div>
            <div>
              <b> {{ $t('ip_address_modal.overview.properties.country') }} </b>
              <div> {{ senderIpAddress.country_name }} </div>
            </div>
            <div>
              <b> {{ $t('ip_address_modal.overview.properties.sender') }} </b>
              <div class="flex flex-col gap-1">
                <div> {{ senderIpAddress.service ?? senderIpAddress.organization }} </div>
                <div class="text-sm"> {{ senderIpAddress.type }} </div>
              </div>
            </div>
            <div>
              <b> {{ $t('ip_address_modal.overview.properties.organisation') }} </b>
              <div> {{ senderIpAddress.organization }} </div>
            </div>
          </div>
        </template>
      </Card>
  </div>
</template>
