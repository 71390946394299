<script setup lang="ts">
import { computed } from 'vue';
import type { PercentageBarItem } from '@/Pages/Statistics/Sender/types/charts';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useFormatters } from '@/Utils/Formatting';

const { items, volumeLabel } = defineProps<{
  items: PercentageBarItem[];
  volumeLabel?: string;
}>();

const total = computed(() => items.reduce((sum, item) => sum + item.value, 0));

const percentageBarChart = computed(() => {
  let maxPercentage = 0;
  const chartItems = items.map((item) => {
    const percentage = parseFloat(((item.value / total.value) * 100).toFixed(1));
    if (percentage > maxPercentage) maxPercentage = percentage;
    return {
      label: item.label,
      value: item.value,
      percentage,
      colour: item.colour,
    };
  });
  return { chartItems, maxPercentage };
});

const { formatPercentage, formatNumber } = useFormatters();
const translate = useTranslate();

const label = volumeLabel ?? translate.translate('global.graphs.legends.volume');

const tooltip = percentageBarChart.value.chartItems.reduce((acc, item) => {
  acc += `
    <span class="inline-flex items-center whitespace-nowrap mt-0.5">
      <span class="h-3 w-3 rounded-full mr-3.5" style="background: ${item.colour};"></span>
      ${item.label}: ${item.percentage}%, ${label}: ${formatNumber(item.value)}
    </span>`;
  return acc;
}, `${label}: ${formatNumber(total.value)}`);

</script>

<template>
  <div
    v-tooltip.top="{value: tooltip, escape: false, pt: {text:'w-fit relative left-1/2 -translate-x-1/2'}}"
    class="flex w-full text-xs text-center text-white rounded overflow-hidden">
    <div
      v-for="(item, index) in percentageBarChart.chartItems"
      :key="index"
      :style="{ 'background': item.colour, 'width': `${item.percentage}%`}"
    >
      <div v-if="percentageBarChart.maxPercentage === item.percentage" class="mt-0.5 py-0.5">
        {{ formatPercentage(item.percentage) }}
      </div>
    </div>
  </div>
</template>
