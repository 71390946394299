<script setup lang="ts">
import { router } from '@inertiajs/vue3';
import Drawer from 'primevue/drawer';
import Tab from 'primevue/tab'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import TabPanels from 'primevue/tabpanels'

import type { DomainResource, FailureReportResource } from '@/types/types.gen';
import { ref } from 'vue';
import KeyPropertiesTabPanel from '@/Pages/Statistics/FailureReports/Partials/KeyPropertiesTabPanel.vue';
import MoreInfoTabPanel from '@/Pages/Statistics/FailureReports/Partials/MoreInfoTabPanel.vue';
import Button from 'primevue/button';
import { useRoute } from 'ziggy-js';

const { report, domain } = defineProps<{
  report: FailureReportResource;
  domain: DomainResource;
}>();

const isVisible = ref(true);

const downloadReportLink = (() => {
  return useRoute()('ui.failure-reports.download', {
    customer: domain.customer_uuid,
    domain: domain.uuid,
    report: report.uuid,
  });
})();

</script>

<template>
  <Drawer
    :header="$t('failure_reports.detail_drawer.header')"
    v-model:visible="isVisible"
    @hide="
      router.get(
        route('ui.failure-reports.index', {
          customer: domain.customer_uuid,
          domain: domain.uuid
        }), {}, {
          preserveState: true
        }
      )
    "
    position="right"
    closeIcon="pi pi-sign-out"
    :pt="{
      root: {
        class: '!max-w-2xl !w-full !h-screen rounded'
      }
    }"
  >
    <Tabs value="0">
      <TabList>
          <Tab value="0">
            {{ $t('failure_reports.detail_drawer.menu.key_properties') }}
          </Tab>
          <Tab value="1">
            {{ $t('failure_reports.detail_drawer.menu.more_info') }}
          </Tab>
      </TabList>
      <TabPanels>
        <KeyPropertiesTabPanel :report="report" />
        <MoreInfoTabPanel :report="report" />
      </TabPanels>
    </Tabs>

    <template #footer>
      <div class="flex flex-row-reverse">
        <a target="_blank" :href="downloadReportLink">
          <Button :label="$t('failure_reports.detail_drawer.action')" />
        </a>
      </div>
    </template>
  </Drawer>
</template>
