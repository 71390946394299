<script setup lang="ts">
import type {
  ConfigurationLayoutProps,
  SignedRoutes,
} from '@/Pages/Dns/types/types';
import Card from 'primevue/card';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import Tabs from 'primevue/tabs';
import { Head, Link } from '@inertiajs/vue3';
import { reactive } from 'vue';
import Toast from 'primevue/toast';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import { upperCase } from 'lodash';

const { tab } = defineProps<{
  layout: ConfigurationLayoutProps;
  signedRoutes: SignedRoutes;
  tab: DomainSettingsTab;
}>();

const state = reactive({
  selectedTabs: tab,
});
</script>

<template>
  <div class="mx-auto mt-8 w-full max-w-screen-lg">
    <Head :title="$t('configurations.instructions.heading', {rdType: upperCase(tab)})" />
    <Toast position="top-center" />
    <div class="mx-2">
      <div class="flex flex-col items-center p-10">
        <img
          class="mb-5"
          width="200"
          :src="layout.branding.ui_logo"
          :alt="layout.partner_name"
        />
        <div
          class="text-xl mb-1 capitalize"
          v-text="$t('configurations.instructions.heading', {rdType: upperCase(tab)})"
        />
        <div
          class="text-xl font-bold"
          v-text="layout.domain_name"
        />
        <div
          class="mt-5 max-w-xl text-center"
          v-text="$t('configurations.description')"
        />
      </div>
      <Card>
        <template #content>
          <Tabs v-model:value="state.selectedTabs">
            <TabList>
              <Link v-if="signedRoutes?.dmarc" :href="signedRoutes.dmarc">
                <Tab :value="DomainSettingsTab.DMARC">DMARC</Tab>
              </Link>
              <Link v-if="signedRoutes?.spf" :href="signedRoutes.spf">
                <Tab :value="DomainSettingsTab.SPF">SPF</Tab>
              </Link>
              <Link v-if="signedRoutes?.dkim" :href="signedRoutes.dkim">
                <Tab :value="DomainSettingsTab.DKIM">DKIM</Tab>
              </Link>
              <Link v-if="signedRoutes?.bimi" :href="signedRoutes.bimi">
                <Tab :value="DomainSettingsTab.BIMI">BIMI</Tab>
              </Link>
              <Link v-if="signedRoutes?.tls" :href="signedRoutes.tls">
                <Tab :value="DomainSettingsTab.TLS">TLS-RPT</Tab>
              </Link>
            </TabList>
            <div class="mt-10">
              <slot />
            </div>
          </Tabs>
        </template>
      </Card>
    </div>
  </div>
</template>
