<script setup lang="ts">
import ToggleButton from 'primevue/togglebutton';
import { onMounted, ref, watch } from 'vue';

const checked = ref(Boolean(localStorage.getItem('darkMode')));

watch(checked, (newVal) => {
  document.documentElement.classList.toggle('g-theme-dark');
  localStorage.setItem('darkMode', newVal ? '1' : '');
});

onMounted(() => {
  if (checked.value) {
    document.documentElement.classList.add('g-theme-dark');
  }
});

</script>

<template>
  <ToggleButton
    v-model="checked"
    :onLabel="$t('global.theme.dark')"
    :offLabel="$t('global.theme.light')"
    onIcon="pi pi-moon"
    offIcon="pi pi-sun"
  />
</template>
