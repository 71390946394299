import oem from '@/Themes/oem';
import hornet from '@/Themes/hornet';
import example from '@/Themes/example';

export default function getTheme(theme: string) {
  return {
    preset: {
      oem: oem,
      hornet: hornet,
      example: example,
    }[theme],
    options: {
      darkModeSelector: '.g-theme-dark',
    },
  };
}
