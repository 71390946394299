<script setup lang="ts">
const { size = 'sm' } = defineProps<{ size?: 'sm' | 'md' }>();

const width = {
  sm: '150px',
  md: '200px',
}[size]
</script>

<template>
  <img
    :src="$page.props.oemConfig.logo"
    :width="width"
  />
</template>
