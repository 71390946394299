<script setup lang="ts">

import { useTranslate } from '@/Utils/Translations/useTranslate';

const { score } = defineProps<{
  score: number;
}>();

const { translate } = useTranslate()

const config = ((score: number) => {
  if (score >= 80) {
    return {
      class: 'bg-success-background text-success-foreground',
      tooltip: translate('domains.tags.score.low.tooltip'),
    };
  } else if (score >= 50) {
    return {
      class: 'bg-warn-background text-warn-foreground',
      tooltip: translate('domains.tags.score.moderate.tooltip'),
    };
  } else {
    return {
      class: 'bg-danger-background text-danger-foreground',
      tooltip: translate('domains.tags.score.high.tooltip'),
    };
  }
})(score);

</script>

<template>
  <div
    class="rounded-full w-10 h-10 flex justify-center items-center"
    :class="config.class"
  >
    <div
      class="text-sm flex items-baseline cursor-default"
      v-tooltip.top="config.tooltip"
    >
      {{score}}
      <div class="text-xs">
        %
      </div>
    </div>

  </div>
</template>
