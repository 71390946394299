import Lara from '@primevue/themes/lara';
import { definePreset } from '@primevue/themes';

export default definePreset(Lara, {
  components: {
    datatable: {
      header: {
        borderWidth: '0',
        padding: '0 0 1rem 0',
      },
      colorScheme: {
        light: {
          header: {
            background: 'transparent',
          },
          headerCell: {
            background: '{content.background}',
            borderColor: '{surface.200}',
            hoverBackground: '{surface.200}',
          },
          bodyCell: {
            borderColor: '{surface.200}',
          },
        },
        dark: {
          header: {
            background: 'transparent',
          },
          headerCell: {
            background: '{content.background}',
            borderColor: '{surface.800}',
            hoverBackground: '{surface.800}',
          },
          bodyCell: {
            borderColor: '{surface.800}',
          },
        },
      },
    },
    breadcrumb: {
      background: 'transparent',
      padding: '0 1.25rem 2rem 1.25rem',
      item: {
        hoverColor: '{navigation.item.color}',
      },
    },
    tabs: {
      tab: {
        borderWidth: '0 0 2px 0',
      },
      tablist: {
        borderWidth: '0 0 1px 0',
      },
      colorScheme: {
        light: {
          tab: {
            background: '{surface.0}',
            hoverBackground: '{surface.100}',
            activeBackground: '{surface.0}',
            activeBorderColor: '{primary.500}',
            activeColor: '{primary.500}',
            color: '{text.tertiary}',
          },
          tablist: {
            background: '{surface.0}',
          },
        },
        dark: {
          tab: {
            background: '{surface.900}',
            hoverBackground: '{surface.800}',
            activeBackground: '{surface.900}',
            activeBorderColor: '{primary.500}',
            activeColor: '{primary.500}',
            color: '{text.tertiary}',
          },
          tablist: {
            background: '{surface.900}',
          },
        },
      },
    },
    tag: {
      root: {
        fontSize: '0.875rem',
        fontWeight: '500',
        padding: '0.25rem 0.5rem',
        gap: '0.25rem',
        borderRadius: '{content.border.radius}',
        roundedBorderRadius: '{border.radius.xl}',
      },
      icon: {
        size: '0.75rem',
      },
      colorScheme: {
        light: {
          primary: {
            background: '{primary.100}',
            color: '{primary.700}',
          },
          secondary: {
            background: '{navy.100}',
            color: '{navy.700}',
          },
          success: {
            background: '{success.background}',
            color: '{success.foreground}',
          },
          info: {
            background: '{info.background}',
            color: '{info.foreground}',
          },
          warn: {
            background: '{warn.background}',
            color: '{warn.foreground}',
          },
          danger: {
            background: '{danger.background}',
            color: '{danger.foreground}',
          },
          contrast: {
            background: '{contrast.background}',
            color: '{contrast.foreground}',
          },
        },
        dark: {
          primary: {
            background: 'color-mix(in srgb, {primary.500}, transparent 60%)',
            color: '{primary.200}',
          },
          secondary: {
            background: 'color-mix(in srgb, {navy.500}, transparent 60%)',
            color: '{navy.200}',
          },
          success: {
            background: 'color-mix(in srgb, {success.default}, transparent 60%)',
            color: '{success.foreground}',
          },
          info: {
            background: 'color-mix(in srgb, {info.default}, transparent 60%)',
            color: '{info.foreground}',
          },
          warn: {
            background: 'color-mix(in srgb, {warn.default}, transparent 60%)',
            color: '{warn.foreground}',
          },
          danger: {
            background: 'color-mix(in srgb, {danger.default}, transparent 60%)',
            color: '{danger.foreground}',
          },
          contrast: {
            background: 'color-mix(in srgb, {contrast.default}, transparent 60%)',
            color: '{contrast.foreground}',
          },
        },
      },
    },
    paginator: {
      colorScheme: {
        light: {
          background: 'transparent',
        },
        dark: {
          background: 'transparent',
        },
      },
    },
    card: {
      shadow: 'none',
      body: {
        padding: '2.25rem',
      },
    },
    button: {
      borderRadius: '{border.radius.xl}',
      colorScheme: {
        light: {
          primary: {
            background: '{primary.500}',
            color: '{surface.0}',
            borderColor: '{primary.500}',
            hoverBackground: '{primary.400}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{primary.400}',
          },
          secondary: {
            background: '{surface.700}',
            color: '{surface.0}',
            borderColor: '{surface.700}',
            hoverBackground: '{surface.600}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{surface.600}',
          },
          outlined: {
            primary: {
              hoverBackground: '{primary.50}',
              color: '{primary.500}',
              hoverColor: '{primary.500}',
              borderColor: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.50}',
              color: '{surface.700}',
              hoverColor: '{surface.600}',
              borderColor: '{surface.700}',
            },
          },
          text: {
            primary: {
              hoverBackground: '{primary.50}',
              color: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.50}',
              color: '{surface.700}',
            },
          },
        },
        dark: {
          primary: {
            background: '{primary.500}',
            color: '{surface.0}',
            borderColor: '{primary.500}',
            hoverBackground: '{primary.400}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{primary.400}',
          },
          secondary: {
            background: '{surface.600}',
            color: '{surface.100}',
            borderColor: '{surface.600}',
            hoverBackground: '{surface.500}',
            hoverColor: '{surface.0}',
            hoverBorderColor: '{surface.500}',
          },
          outlined: {
            primary: {
              hoverBackground: 'color-mix(in srgb, {primary.500}, transparent 80%)',
              color: '{primary.500}',
              hoverColor: '{primary.600}',
              borderColor: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.800}',
              color: '{surface.300}',
              hoverColor: '{surface.400}',
              borderColor: '{surface.300}',
            },
          },
          text: {
            primary: {
              hoverBackground: 'color-mix(in srgb, {primary.500}, transparent 80%)',
              color: '{primary.500}',
            },
            secondary: {
              hoverBackground: '{surface.800}',
              color: '{surface.300}',
            },
          },
        },
      },
    },
    menu: {
      background: '{content.background}',
      borderRadius: '{content.borderRadius}',
      item: {
        borderRadius: '{border.radius.md}',
      },
      colorScheme: {
        light: {
          background: '{content.background}',
        },
        dark: {
          background: '{content.background}',
        },
      },
    },
    menubar: {
      background: '{content.background}',
      borderRadius: '{content.borderRadius}',
      item: {
        borderRadius: '{border.radius.md}',
      },
      colorScheme: {
        light: {
          background: '{content.background}',
        },
        dark: {
          background: '{content.background}',
        },
      },
    },
    panel: {
      borderRadius: '{content.borderRadius}',
      header: {
        borderRadius: '{content.borderRadius} {content.borderRadius} 0 0',
      },
    },
    accordion: {
      colorScheme: {
        light: {
          header: {
            background: '{surface.50}',
            activeBackground: '{content.background}',
            hoverBackground: '{content.hoverBackground}',
            color: '{text.tertiary}',
          },
        },
        dark: {
          header: {
            background: '{surface.800}',
            activeBackground: '{content.background}',
            hoverBackground: '{content.hoverBackground}',
            color: '{text.tertiary}',
          },
        },
      },
    },
    fileupload: {
      header: {
        borderRadius: '{content.borderRadius} {content.borderRadius} 0 0',
      },
    },
  },
  primitive: {
    green: {
      50: '#E8F9ED',
      100: '#E8F9ED',
      200: '#B5ECC6',
      300: '#78D996',
      400: '#3BCA64',
      500: '#15B546',
      600: '#169E40',
      700: '#128836',
      800: '#0F772F',
      900: '#0C6327',
    },
    red: {
      50: '#FFF1F2',
      100: '#FFF1F2',
      200: '#F2BABE',
      300: '#C9666A',
      400: '#BA4143',
      500: '#B71322',
      600: '#90211C',
      700: '#871F19',
      800: '#771610',
      900: '#65131A',
    },
    orange: {
      50: '#FEF4E9',
      100: '#FEF4E9',
      200: '#FAD9B5',
      300: '#EEB77A',
      400: '#E9A961',
      500: '#D97B12',
      600: '#CC7411',
      700: '#BE6B0F',
      800: '#B0630C',
      900: '#96540B',
    },
    blue: {
      50: '#EDF5FF',
      100: '#EDF5FF',
      200: '#B1D8FF',
      300: '#7EBEFF',
      400: '#449EFC',
      500: '#0073EA',
      600: '#0068D3',
      700: '#0060C3',
      800: '#0053A8',
      900: '#00438B',
      950: '#00438B',
    },
    purple: {
      50: '#F7EEFF',
      100: '#F7EEFF',
      200: '#DCC1F5',
      300: '#BB88ED',
      400: '#A86EE0',
      500: '#9756D6',
      600: '#8C48CE',
      700: '#7D38C0',
      800: '#6A26AD',
      900: '#551C8B',
    },
    gray: {
      50: '#F7F7F7',
      100: '#F7F7F7',
      200: '#E3E3E3',
      300: '#CBCBCB',
      400: '#B8B8B8',
      500: '#A5A5A6',
      600: '#9C9C9C',
      700: '#909090',
      800: '#808080',
      900: '#656565',
    },
    navy: {
      50: '#F7F9FB',
      100: '#EEF2F6',
      200: '#CCD5DE',
      300: '#ABB8C7',
      400: '#899CAF',
      500: '#687F98',
      600: '#466280',
      700: '#384E66',
      800: '#1C2733',
      900: '#151D26',
      950: '#0E141A',
    },
    borderRadius: {
      none: '0',
      xs: '2px',
      sm: '4px',
      md: '8px',
      lg: '16px',
      xl: '24px',
    },
  },
  semantic: {
    disabledOpacity: '0.4',
    primary: {
      50: '{blue.50}',
      100: '{blue.100}',
      200: '{blue.200}',
      300: '{blue.300}',
      400: '{blue.400}',
      500: '{blue.500}',
      600: '{blue.600}',
      700: '{blue.700}',
      800: '{blue.800}',
      900: '{blue.900}',
    },
    content: {
      borderRadius: '{border.radius.lg}',
    },
    formField: {
      borderRadius: '{border.radius.md}',
    },
    colorScheme: {
      light: {
        surface: {
          0: '#FEFFFF',
          50: '{navy.50}',
          100: '{navy.100}',
          200: '{navy.200}',
          300: '{navy.300}',
          400: '{navy.400}',
          500: '{navy.500}',
          600: '{navy.600}',
          700: '{navy.700}',
          800: '{navy.800}',
          900: '{navy.900}',
          950: '{navy.950}',
        },
        primary: {
          color: '{primary.600}',
          contrastColor: '{surface.0}',
          hoverColor: '{primary.800}',
          activeColor: '{primary.700}',
        },
        text: {
          color: '{surface.900}',
          secondary: '{surface.700}',
          tertiary: '{surface.600}',
          hoverColor: '{primary.500}',
          focusColor: '{primary.600}',
        },
        highlight: {
          background: 'color-mix(in srgb, {primary.500}, transparent 80%)',
          focusBackground: '{primary.700}',
          color: '{primary.500}',
          focusColor: '{primary.500}',
        },
        ground: {
          background: '{surface.100}',
        },
        content: {
          background: '{surface.0}',
          hoverBackground: '{surface.100}',
          borderColor: '{surface.200}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        section: {
          background: '{surface.50}',
          foreground: '{surface.0}',
        },
        graphs: {
          positive: '{green.500}',
          negative: '{red.500}',
          warning: '{orange.500}',
          fill: '{surface.500}',
          background: '{surface.200}',
          color1: '{purple.500}',
        },
        success: {
          default: '{green.500}',
          background: '{green.100}',
          foreground: '{green.500}',
        },
        warn: {
          default: '{orange.500}',
          background: '{orange.100}',
          foreground: '{orange.500}',
        },
        danger: {
          default: '{red.500}',
          background: '{red.100}',
          foreground: '{red.500}',
          darker: '{red.800}',
        },
        info: {
          default: '{purple.500}',
          background: '{purple.100}',
          foreground: '{purple.500}',
        },
        contrast: {
          default: '{surface.500}',
          background: '{surface.100}',
          foreground: '{surface.700}',
        },
        formField: {
          borderColor: '{surface.300}',
          background: '{surface.50}',
          disabledBackground: '{surface.200}',
          disabledColor: '{surface.400}',
          disabledBorderColor: '{surface.200}',
        },
        navigation: {
          item: {
            focusColor: '{primary.500}',
          },
        },
        list: {
          option: {
            selectedFocusColor: '{surface.0}',
          },
        },
      },
      dark: {
        surface: {
          0: '#FEFFFF',
          50: '{navy.50}',
          100: '{navy.100}',
          200: '{navy.200}',
          300: '{navy.300}',
          400: '{navy.400}',
          500: '{navy.500}',
          600: '{navy.600}',
          700: '{navy.700}',
          800: '{navy.800}',
          900: '{navy.900}',
          950: '{navy.950}',
        },
        primary: {
          color: '{primary.50}',
          contrastColor: '{primary.950}',
          hoverColor: '{primary.200}',
          activeColor: '{primary.300}',
        },
        text: {
          color: '{surface.0}',
          secondary: '{surface.100}',
          tertiary: '{surface.200}',
          hoverColor: '{primary.500}',
          focusColor: '{primary.400}',
        },
        highlight: {
          background: 'color-mix(in srgb, {primary.500}, transparent 80%)',
          focusBackground: '{primary.850}',
          color: '{primary.100}',
          focusColor: '{primary.100}',
        },
        ground: {
          background: '{surface.950}',
        },
        content: {
          background: '{surface.900}',
          hoverBackground: '{surface.700}',
          borderColor: '{surface.700}',
          color: '{text.color}',
          hoverColor: '{text.hover.color}',
        },
        section: {
          background: '{surface.950}',
          foreground: '{surface.900}',
        },
        graphs: {
          positive: '{green.500}',
          negative: '{red.500}',
          warning: '{orange.500}',
          fill: '{surface.500}',
          background: '{surface.700}',
          color1: '{purple.500}',
        },
        success: {
          default: '{green.500}',
          background: '{green.900}',
          foreground: '{green.200}',
        },
        warn: {
          default: '{orange.500}',
          background: '{orange.900}',
          foreground: '{orange.200}',
        },
        danger: {
          default: '{red.500}',
          background: '{red.900}',
          foreground: '{red.200}',
          darker: '{red.800}',
        },
        info: {
          default: '{purple.500}',
          background: '{purple.900}',
          foreground: '{purple.200}',
        },
        contrast: {
          default: '{surface.500}',
          background: '{surface.900}',
          foreground: '{surface.200}',
        },
        formField: {
          borderColor: '{surface.700}',
          background: '{surface.950}',
          disabledBackground: '{surface.800}',
          disabledColor: '{surface.600}',
          disabledBorderColor: '{surface.800}',
        },
        navigation: {
          item: {
            focusColor: '{primary.500}',
          },
        },
        list: {
          option: {
            selectedFocusBackground: '{highlight.background}',
          },
        },
      },
    },
  },
});
