<script setup lang="ts">
import Card from 'primevue/card';
import type { SenderComplianceStatistics } from '@/Pages/Statistics/Sender/types';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import Legend from '@/Components/Legend.vue';
import { useFormatters } from '@/Utils/Formatting';

const { data } = defineProps<{
  data: SenderComplianceStatistics;
}>();

const { translate } = useTranslate();
const { formatPercentage } = useFormatters();

const chartData: ChartData<'doughnut'> = {
  labels: [
    translate('senders_reports.senders.legends.passing_dmarc'),
    translate('senders_reports.senders.legends.failing_dmarc'),
    translate('senders_reports.senders.legends.forward'),
  ],
  datasets: [
    {
      data: [
        data.configured,
        data.unconfigured,
        data.partially_configured,
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-negative'),
        getCssVariable('--p-graphs-warning'),
      ],
      borderWidth: 0,
    },
  ],
};

const total = data.configured + data.partially_configured + data.unconfigured;

const configuredPercentage = (data.configured / total) * 100;

</script>

<template>
  <Card>
    <template #content>
      <div class="flex justify-between">
        <div class="flex flex-col gap-3">
          <div
            class="font-semibold text-lg"
            v-text="$t('exposure_report.sender_readiness.heading')"
          />
          <div class="flex flex-col">
            <Legend
              :icon-size="3"
              :text="`${$t('exposure_report.sender_readiness.legends.configured.heading')} (${data.configured})`"
              :icon-class="['bg-graphs-positive']"
            />
            <Legend
              :icon-size="3"
              :text="`${$t('exposure_report.sender_readiness.legends.partially.heading')} (${data.partially_configured})`"
              :icon-class="['bg-graphs-warning']"
            />
            <Legend
              :icon-size="3"
              :text="`${$t('exposure_report.sender_readiness.legends.unconfigured.heading')} (${data.unconfigured})`"
              :icon-class="['bg-graphs-negative']"
            />
          </div>
        </div>

        <DoughnutChart :data="chartData">
            <h6 class="font-bold">
              {{ formatPercentage(configuredPercentage) }}
            </h6>
            <div
              class="text-xs"
              v-text="$t('exposure_report.sender_readiness.legends.configured.heading')"
            />
        </DoughnutChart>
      </div>

    </template>
  </Card>
</template>
