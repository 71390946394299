<script setup lang="ts">
import Menubar from 'primevue/menubar';
import type { MenuItem as PrimeVueMenuItem } from 'primevue/menuitem';
import { computed, reactive } from 'vue';
import { type SenderCategory, SenderReportCategory } from '@/types/types.gen';
import type { SenderMenuBarLink } from '@/Pages/Statistics/Sender/types/menuBars';

const { activeItem, items } = defineProps<{
  activeItem?: string;
  items: SenderMenuBarLink[];
}>();

// This overcomplicated in order to get TS to behave. `typeof items[0][0]` should be enough.
type MenuItemChangedArg = (typeof items)[0] extends [SenderCategory, string] ? SenderCategory
  : (typeof items)[0] extends [SenderReportCategory, string] ? SenderReportCategory
      : (typeof items)[0] extends [string, string] ? string : never;

const emit = defineEmits<{
  (e: 'menu-item-changed', menuItem: MenuItemChangedArg): void;
}>();

const state = reactive({
  activeItem,
});
const menuItems = computed((): PrimeVueMenuItem[] => {
  return items
    .map(([key, label]): PrimeVueMenuItem => {
      if (typeof label === 'undefined') {
        return { separator: true };
      }

      return {
        label,
        key,
        command: () => {
          state.activeItem = key;
          emit('menu-item-changed', key as MenuItemChangedArg);
        },
        class: key?.toLowerCase() === state.activeItem?.toLowerCase() ? 'p-menubar-item-active' : '',
      };
    });
});
</script>

<template>
  <Menubar :model="menuItems" />
</template>
