<script setup lang="ts">
import TabPanel from 'primevue/tabpanel';
import type { FailureReportResource } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useFormatters } from '@/Utils/Formatting';
import { parseISO } from 'date-fns';
import { defaultTo, isArray } from 'lodash';

const { report } = defineProps<{ report: FailureReportResource }>()

const { formatDate } = useFormatters();

const { translate } = useTranslate();

const keyProperties = [
  {
    value: formatDate(parseISO(report.arrival_date)),
    label: translate('failure_reports.properties.date'),
  }, {
    value: report.feedback_type,
    label: translate('failure_reports.properties.feedback_type'),
  }, {
    value: report.auth_failure,
    label: translate('failure_reports.properties.authentication_failure'),
  }, {
    value: report.delivery_result,
    label: translate('failure_reports.properties.delivery_results'),
  }, {
    value: report.source_ip,
    label: translate('failure_reports.properties.source_ip'),
  }, {
    value: report.incidents,
    label: translate('failure_reports.properties.incidents'),
  }, {
    value: report.original_mail_from.replace(/[<>]/g, ''),
    label: translate('failure_reports.properties.return_path'),
  }, {
    value: report.reporting_mta,
    label: translate('failure_reports.properties.reporting_mta'),
  }, {
    value: isArray(report.reported_domain) ? report.reported_domain.join(', ') : '-',
    label: translate('failure_reports.properties.reporting_domains'),
  },
];

</script>

<template>
  <TabPanel value="0">
    <div class="flex flex-col mt-5 gap-2">
      <div v-for="(property, index) in keyProperties" class="flex" :key="index">
        <div class="w-3/6 font-semibold" v-text="property.label"/>
        <div v-text="defaultTo(property.value, '-')"/>
      </div>
    </div>
  </TabPanel>
</template>
