<script setup lang="ts">
import { ref, watch } from 'vue';
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import CountryFlag from '@/Components/CountryFlag.vue';
import axios from 'axios';
import { useRoute } from 'ziggy-js';
import { usePage } from '@inertiajs/vue3';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import type { LanguageSelectorItem } from '@/Layouts/types/LanguageSelectorTypes';
import type { MenuItemCommandEvent } from 'primevue/menuitem';
import type { LanguageCode } from '@/types';
import TranslatorService from '@/Utils/Translations/TranslatorService';

const menu = ref();
const { props } = usePage();
const { user } = props.auth;
const { updateLanguage, language } = useTranslate();

const getFlagCode = (code: string) => (code === 'en' ? 'us' : code);
const flagCode = ref(getFlagCode(user?.language.code ?? TranslatorService.fallbackLang));

const toggleMenu = (event: Event) => menu.value?.toggle(event);

const updateActiveLang = async (event: MenuItemCommandEvent) => {
  const newModel = menuModel.find(item => item.label === event.item.label)!;

  await updateCustomerLocale(newModel.timezone, newModel.language_code);
}

watch(language, async (newVal: LanguageCode | undefined) => {
  const newModel = menuModel.find(item => item.language_code === newVal)!;

  await updateCustomerLocale(newModel.timezone, newModel.language_code);
});

const updateCustomerLocale = async (timezone: string, language: LanguageCode) => {
  if (!user) return;

  const route = useRoute()('api.customers.update', [user.partner_uuid, user.customer_uuid]);

  updateLanguage(language);
  flagCode.value = getFlagCode(language);

  await axios.put(route, { default_timezone: timezone, default_language: language });
};

const menuModel: LanguageSelectorItem[] = [
  {
    command: updateActiveLang,
    language_code: 'en',
    timezone: 'America/New_York',
    country_code: 'us',
    label: 'English',
  },
  {
    command: updateActiveLang,
    language_code: 'de',
    timezone: 'Europe/Berlin',
    country_code: 'de',
    label: 'Deutsch',
  },
  {
    command: updateActiveLang,
    language_code: 'es',
    timezone: 'America/Mexico_City',
    country_code: 'es',
    label: 'Español',
  },
  {
    command: updateActiveLang,
    language_code: 'fr',
    timezone: 'Europe/Paris',
    country_code: 'fr',
    label: 'Français',
  },
]

</script>

<template>
  <Button
    type="button"
    text
    icon="pi pi-ellipsis-v"
    @click="toggleMenu"
    size="small"
    rounded
    severity="secondary"
    aria-haspopup="true"
    aria-controls="domain_menu"
  >
    <template #icon>
      <CountryFlag :code="flagCode" />
    </template>
  </Button>
  <Menu
    ref="menu"
    id="overlay_menu"
    :model="menuModel"
    :popup="true"
  >
    <template #item="{ item, props }">
      <a class="flex items-center" v-bind="props.action">
        <CountryFlag :code="item.country_code" class="mr-2" />
        <span>{{ item.label }}</span>
      </a>
    </template>
  </Menu>
</template>
