import { AlertType } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';

export enum AlertCategory {
  none = 'none',
  status = 'status',
  configuration = 'configuration',
  mail_senders = 'mail_senders',
  reports = 'reports',
}

export interface AlertConfig {
  type: AlertType;
  category: AlertCategory;
  group: string;
  description: string;
  hasConditions: boolean;
}

export const useAlertConfig = (): AlertConfig[] => {
  const { translate } = useTranslate();

  return [
    {
      type: AlertType.DOMAIN_SCORE_CHANGE,
      category: AlertCategory.status,
      group: 'domain',
      description: translate('alerts.activity.options.domain-score-change.description'),
      hasConditions: false,
    },
    {
      type: AlertType.DNS_DELEGATION_VERIFIED,
      category: AlertCategory.status,
      group: 'domain',
      description: translate('alerts.activity.options.dns-delegation-verified.description'),
      hasConditions: true,
    },
    {
      type: AlertType.DNS_DELEGATION_UNVERIFIED,
      category: AlertCategory.status,
      group: 'domain',
      description: translate('alerts.activity.options.dns-delegation-unverified.description'),
      hasConditions: true,
    },
    {
      type: AlertType.DOMAIN_SETTINGS_CHANGE,
      category: AlertCategory.configuration,
      group: 'domain',
      description: translate('alerts.activity.options.domain-settings-change.description'),
      hasConditions: true,
    },
    {
      type: AlertType.DOMAIN_COMPLIANCE_DROP,
      category: AlertCategory.mail_senders,
      group: 'domain',
      description: translate('alerts.activity.options.domain-compliance-drop.description'),
      hasConditions: false,
    },
    {
      type: AlertType.TYPICALLY_AUTHORIZED_SENDER_FOUND,
      category: AlertCategory.mail_senders,
      group: 'domain',
      description: translate('alerts.activity.options.typically-authorized-sender-found.description'),
      hasConditions: false,
    },
    {
      type: AlertType.SENDER_THREAT_LEVEL_CHANGE,
      category: AlertCategory.mail_senders,
      group: 'domain',
      description: translate('alerts.activity.options.sender-threat-level-change.description'),
      hasConditions: false,
    },
    {
      type: AlertType.CUSTOMER_EXPOSURE_REPORT,
      category: AlertCategory.reports,
      group: 'domain',
      description: translate('alerts.activity.options.customer-exposure-report.description'),
      hasConditions: false,
    },
  ];
}
