import type { ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
import { useFormatters } from '@/Utils/Formatting';

export const makeCustomTooltip = <T extends ChartType>(
  callback: (item: TooltipItem<T>) => string | string[] | void,
  hideTitle: boolean = false,
): ChartOptions<T> => {
  return {
    plugins: {
      tooltip: {
        enabled: false,
        callbacks: {
          label(tooltipItem: TooltipItem<T>): string | string[] | void {
            return callback(tooltipItem);
          },
          ...(hideTitle ? { title: () => '' } : {}),
        },
      },
    },
  } as ChartOptions<T>;
};

export const asPercentageVolume = <T extends 'doughnut' | 'bar' | 'line'>(makeTotal: (date: string) => number) =>
  (item: TooltipItem<T>) => {
    const { formatPercentage, formatNumber } = useFormatters();

    const index = item.dataIndex;
    const dataset = item.dataset as ChartDataset<T>;
    const datum = dataset.data[index] as number;
    // dataset label is only present in the case of time-series graphs
    const label = dataset.label ?? item.label;
    const date = item.label;

    return `${label}: ${formatPercentage((datum / makeTotal(date)) * 100)}, Volume: ${formatNumber(datum)}`;
  };
