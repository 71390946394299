<script setup lang="ts">
import type { ChartData } from 'chart.js';
import { getCssVariable } from '@/Utils/Styling';
import { computed, type ComputedRef } from 'vue';
import type { BlacklistedSendersCount } from '@/Pages/Statistics/Sender/types/charts';
import { useFormatters } from '@/Utils/Formatting';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import { asPercentageVolume, makeCustomTooltip } from '@/Components/Charts/Helpers';

const { formatPercentage, strLimit } = useFormatters();
const { data, colours } = defineProps<{
  data: BlacklistedSendersCount[];
  colours: { bg: string; var: `--p-${string}` }[];
}>();

const total = computed(() => data.reduce((carry, sender) => carry + sender.count, 0));
const makeTotal = () => total.value;
const options = makeCustomTooltip(asPercentageVolume(makeTotal));

const chartData: ComputedRef<ChartData<'doughnut'>> = computed(() => {
  return ({
    labels: data.map(({
      domain,
      count,
    }) => `${strLimit(domain, 12)} (${count}) ${formatPercentage(count / total.value)}`),
    datasets: [
      {
        data: data.map(({ count }) => count),
        backgroundColor: data.map((_, i) => getCssVariable(colours[i].var)),
        borderWidth: 0,
      },
    ],
  });
});

</script>

<template>
  <DoughnutChart :data="chartData"  :options="options"  size="medium" />
</template>
