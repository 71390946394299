<script setup lang="ts">
import Tag from 'primevue/tag';
import type { DmarcPolicy } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { translate } = useTranslate();
const { policy } = defineProps<{
  policy: DmarcPolicy;
}>();

const config = {
  none: {
    value: translate('ip_address_modal.table.action_applied.tags.delivered.value'),
    severity: 'info',
    tooltip: translate('ip_address_modal.table.action_applied.tags.delivered.tooltip'),
  },
  quarantine: {
    value: translate('ip_address_modal.table.action_applied.tags.quarantine.value'),
    severity: 'warn',
    tooltip: translate('ip_address_modal.table.action_applied.tags.quarantine.tooltip'),
  },
  reject: {
    value: translate('ip_address_modal.table.action_applied.tags.reject.value'),
    severity: 'success',
    tooltip: translate('ip_address_modal.table.action_applied.tags.reject.tooltip'),
  },
}[policy];

</script>

<template>
  <Tag
    :value="config.value"
    :severity="config.severity"
    v-tooltip.top="$t('ip_address_modal.table.action_applied.info.tooltip') + `: ${config.tooltip}`"
  />
</template>
