<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { sumBy } from 'lodash';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import DoughnutChart from '@/Components/Charts/DoughnutChart.vue';
import type { StatisticsTotals } from '@/Pages/Statistics/Sender/types';
import { computed, type ComputedRef } from 'vue';
import { asPercentageVolume, makeCustomTooltip } from '@/Components/Charts/Helpers';

const { data } = defineProps<{ data: StatisticsTotals[] }>();
const { translate } = useTranslate();

const makeTotal = () => sumBy(data, 'total_incidents');
const options = makeCustomTooltip(asPercentageVolume(makeTotal), true);

const chartData: ComputedRef<ChartData<'doughnut'>> = computed(() => ({
  labels: [
    translate('senders_reports.senders.legends.delivered'),
    translate('senders_reports.senders.legends.overridden_as_delivered'),
    translate('senders_reports.senders.legends.overridden_as_not_delivered'),
    translate('senders_reports.senders.legends.not_delivered'),
  ],
  datasets: [
    {
      data: [
        sumBy(data, 'total_delivered'),
        sumBy(data, 'total_permitted'),
        sumBy(data, 'total_blocked'),
        sumBy(data, 'total_complete_failures'),
      ],
      backgroundColor: [
        getCssVariable('--p-graphs-positive'),
        getCssVariable('--p-graphs-color1'),
        getCssVariable('--p-graphs-warning'),
        getCssVariable('--p-graphs-negative'),
      ],
      borderWidth: 0,
    },
  ],
}));

</script>
<template>
  <DoughnutChart :data="chartData" :options="options">
    <slot />
  </DoughnutChart>
</template>
