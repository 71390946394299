<script setup lang="ts">
import ChartCard from '@/Components/Cards/ChartCard.vue';
import { sumBy } from 'lodash';
import { useFormatters } from '@/Utils/Formatting';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import SendersChart from '@/Pages/Statistics/Sender/Charts/SendersChart.vue';
import { computed } from 'vue';

const { groups } = defineProps<{
  groups: GroupedStatistics[];
  timelineStatistics: TimelineStatisticsResource[];
}>();

const { formatNumber } = useFormatters();

const ipAddressesCount = computed(() => sumBy(groups, 'total_sender_ip_addresses'));
</script>
<template>
  <ChartCard
    :title="$t('senders_reports.cards.senders')"
  >
    <template #lead>
      <h6 class="mt-4 text-2xl">{{ formatNumber(groups.length) }}</h6>
      <span>
        {{ $t('senders_reports.cards.ip_addresses') }} ({{ formatNumber(ipAddressesCount)}})
      </span>
    </template>
    <template #graph>
      <SendersChart :data="timelineStatistics"/>
    </template>
  </ChartCard>
</template>
