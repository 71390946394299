<script setup lang="ts">
import InnerCard from '@/Components/InnerCard.vue';
import Button from 'primevue/button';
import type { DomainResource, MtaStsSettingResource, TlsRptSettingResource } from '@/types/types.gen';
import { reactive } from 'vue';
import DisableMtaStsSettingsModel from '@/Pages/Domains/TlsRptSettings/Partials/DisableMtaStsSettingsModal.vue';
import DisableTlsRptSettingsModel from '@/Pages/Domains/TlsRptSettings/Partials/DisableTlsRptSettingsModal.vue';

const { domain } = defineProps<{
  domain: DomainResource;
  mtaStsSettings: MtaStsSettingResource;
  tlsRptSettings: TlsRptSettingResource;
}>();

const state = reactive({
  showTlsRptModel: false,
  showMtaStsModal: false,
});
</script>

<template>
  <InnerCard>
    <template #content>
      <p v-text="$t('mta_settings.enable.message')" />
      <div class="flex gap-4">
        <Button
          v-if="tlsRptSettings.enable_tls_rpt"
          @click="state.showTlsRptModel = true"
          type="submit"
          class="mt-3"
          severity="secondary"
          size="small"
          :label="$t('tls_settings.disable.action')"
          outlined
        />

        <Button
          v-if="mtaStsSettings.is_record_enabled"
          @click="state.showMtaStsModal = true"
          type="submit"
          class="mt-3"
          severity="secondary"
          size="small"
          :label="$t('mta_settings.disable.action')"
          outlined
        />
      </div>
    </template>
    <p v-text="$t('mta_settings.enable.message')" />
  </InnerCard>

  <DisableMtaStsSettingsModel
    v-if="state.showMtaStsModal"
    :domain="domain"
  />
  <DisableTlsRptSettingsModel
    v-if="state.showTlsRptModel"
    :domain="domain"
  />
</template>
