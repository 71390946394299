import { fallbackLocale, useFormatters } from '@/Utils/Formatting/index';
import { type App, watch } from 'vue';
import type { Page } from '@inertiajs/core';

export const FormattingPlugin = {
  install(_app: App, page: Page) {
    const installPlugin = (newAuth: typeof page.props.auth) => {
      let locale = fallbackLocale;

      if (newAuth?.user) {
        const { language } = newAuth.user;
        locale = language.locale;
      }

      const { updateLocale } = useFormatters();
      updateLocale(locale);
    };

    installPlugin(page.props.auth);

    watch(page, (newPage: Page) => installPlugin(newPage.props.auth));
  },
};
