<script lang="ts" setup>
import type { BrandingResource, DomainResource, PartnerResource } from '@/types/types.gen';
import DomainAvatar from '@/Pages/Domains/Partials/DomainAvatar.vue';
import { inject } from 'vue';

defineProps<{
  partner: PartnerResource;
  domain: DomainResource;
}>();

const branding = <BrandingResource>inject('branding');
</script>

<template>
  <div class="flex items-center justify-between mb-12">
      <div>
        <img
          v-if="branding.ui_logo"
          :src="branding.ui_logo"
          :alt="partner.name"
          width="200"
        />
      </div>

      <div class="flex items-center justify-center gap-6">

        <div>
          <div
            class="text-xl leading-5 text-right"
            v-text="$t('exposure_report.heading')"
          />
          <div
            class="text-lg font-semibold text-right"
            v-text="domain.domain_name"
          />
        </div>
        <DomainAvatar :domain="domain" size="large"/>
      </div>
  </div>
</template>
