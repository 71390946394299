import { UiRestriction } from '@/types/types.gen';
import { usePage } from '@inertiajs/vue3';

export const useFeatureRestriction = (feature: UiRestriction | UiRestriction[]) => {
  const { props: { oemConfig } } = usePage();

  if (Array.isArray(feature)) {
    return feature.some(
      feature => oemConfig.ui_restrictions.some(
        uiRestriction => uiRestriction === feature,
      ),
    );
  }

  return oemConfig.ui_restrictions.some(
    uiRestriction => uiRestriction === feature,
  );
}
