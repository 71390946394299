<script setup lang="ts">
import AppLayout from '@/Layouts/AppLayout.vue';
import Card from 'primevue/card';
import Column from 'primevue/column';
import Tag from 'primevue/tag';
import { Link } from '@inertiajs/vue3';
import ListingTable from '@/Components/ListingTable.vue';
import Button from 'primevue/button';
import type { CustomerResource, CustomerStatus } from '@/types/types.gen';
import type { PaginatedResponse } from '@/types/http';

const getAccountStatusChipColor = (status: CustomerStatus) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'poc':
    case 'trial':
      return 'info';
    case 'suspended':
    case 'disabled':
      return 'danger';
    default:
      return 'secondary';
  }
};

defineProps<{
  customers: PaginatedResponse<CustomerResource>;
}>();
</script>

<template>
  <AppLayout title="Customers">
    <Card class="w-full">
      <template #title>{{ $t('customers.title') }}</template>
      <template #content>
        <ListingTable :value="customers?.data">
          <Column field="name" header="Name" />
          <Column>
            <template #body="{ data }">
              <div class="flex flex-row-reverse gap-3">
                <Tag :value="data.type" class="capitalize" />
                <Tag
                  :value="data.status"
                  :severity="getAccountStatusChipColor(data.status)"
                  class="capitalize"
                />
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{ data }">
              <div class="flex flex-row-reverse">
                <Link
                  :href="
                    route('ui.customers.destroy', {
                      partner: data.partner_uuid,
                      customer: data.uuid
                    })
                  "
                  method="delete"
                >
                  <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    severity="danger"
                  />
                </Link>
              </div>
            </template>
          </Column>
        </ListingTable>
      </template>
    </Card>
  </AppLayout>
</template>
