<script setup lang="ts">
import AppLayout from '@/Layouts/AppLayout.vue';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Column from 'primevue/column';
import { Link } from '@inertiajs/vue3';
import Tag from 'primevue/tag';
import type { PaginatedResponse } from '@/types/http';
import {
  type CustomerResource,
  type DomainResource, UiRestriction,
} from '@/types/types.gen';
import DomainAvatar from '@/Pages/Domains/Partials/DomainAvatar.vue';
import DomainsSettingsMenu from '@/Pages/Domains/Partials/DomainsSettingsMenu.vue';
import DataTable, { type DataTableFilterMeta } from 'primevue/datatable';
import InputIcon from 'primevue/inputicon';
import IconField from 'primevue/iconfield';
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from '@primevue/core/api';
import { reactive } from 'vue';
import { route } from 'ziggy-js';
import LogsDrawer from '@/Pages/Logs/LogsDrawer.vue';
import ProtectionScoreBadge from '@/Pages/Domains/Partials/ProtectionScoreBadge.vue';
import DmarcPolicyTag from '@/Pages/Domains/Partials/DmarcPolicyTag.vue';
import DomainStatusTag from '@/Pages/Domains/Partials/DomainStatusTag.vue';
import { useFeatureRestriction } from '@/Utils/useFeatureRestriction';
import NavCrumbs from '@/Components/NavCrumbs.vue';

defineProps<{
  customer: CustomerResource;
  domains: PaginatedResponse<DomainResource>;
}>();

const state = reactive({
  logs: {
    visible: false,
    domain_name: '',
    path: '',
  },
  filters: {
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  } as Record<string, DataTableFilterMeta>,
});

const openLogsDrawer = (domain: DomainResource) => {
  state.logs = {
    visible: true,
    domain_name: domain.domain_name,
    path: route('api.logs.domain', [
      domain.customer_uuid,
      domain.uuid,
    ]),
  };
}

const domainCreationLimited = useFeatureRestriction(UiRestriction.NO_DOMAIN_CREATE);

</script>

<template>
  <AppLayout :title="$t('domains.listing.title')">
    <NavCrumbs :model="[{ label: $t('domains.listing.title') }]" />
    <LogsDrawer
      @hide="state.logs.visible = false"
      v-if="state.logs.visible"
      :path="state.logs.path"
      :header="$t('logs.header', {
        type: state.logs.domain_name
      })"
    />
    <Card class="w-full">
      <template #content>
        <DataTable
          v-model:filters="state.filters"
          :value="domains.data"
          :global-filter-fields="['domain_name']"
          data-key="uuid"
          paginator
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
          :currentPageReportTemplate="'&nbsp ' + $t('global.pagination.showing') + ' {first} ' + $t('global.pagination.to') + ' {last} ' + $t('global.pagination.of') + ' {totalRecords}'"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :sort-order="-1"
          sort-field="is_favorite"
        >
          <template #empty>{{ $t('tables.not_found') }}</template>
          <template #header>
            <div class="flex justify-between">
              <IconField>
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  :placeholder="$t('tables.search')"
                  v-model="state.filters.global.value"
                />
              </IconField>
              <Link
                v-if="!domainCreationLimited"
                :href="route('ui.domains.create', [customer.uuid])"
                preserve-state
              >
                <Button :label="$t('global.buttons.add_new')" icon="pi pi-plus" />
              </Link>
            </div>
          </template>
          <Column
            :sortable="true"
            field="domain_name"
            :header="$t('domains.listing.table.header.name')"
            filterField="name"
            headerClass="w-3/6"
          >
            <template #body="{ data }: { data: DomainResource }">
              <div class="flex items-center">
                <DomainAvatar :domain="data" />
                <div class="ml-4 font-medium leading-6 text-color">
                  {{ data.domain_name }}
                </div>
                <div
                  v-if="data.is_favorite"
                  class="pi pi-star text-info-default ml-4 text-lg"
                  v-tooltip.top="$t('domains.tags.primary.tooltip')"
                />
              </div>
            </template>
          </Column>

          <Column :header="$t('domains.listing.table.header.score')" :sortable="true" field="score">
            <template #body="{ data }">
              <ProtectionScoreBadge
                :score="data.score"
              />
            </template>
          </Column>

          <Column :header="$t('domains.listing.table.header.status')">
            <template #body="{ data }">
              <DomainStatusTag
                :domain="data"
              />
            </template>
          </Column>

          <Column
            :header="$t('domains.listing.table.header.policy')"
            field="policy"
            :sortable="true"
          >
            <template #body="{ data }">
              <DmarcPolicyTag
                :policy="data.policy"
              />
            </template>
          </Column>

          <Column :header="$t('domains.listing.table.header.type')" :sortable="true" field="type">
            <template #body="{ data }">
              <Tag
                class="capitalize cursor-default"
                :value="data.type === 'parked' ? $t('domains.tags.type.parked.value') : $t('domains.tags.type.active.value')"
                :severity="data.type === 'parked' ? 'secondary' : 'success'"
                v-tooltip.top="data.type === 'parked' ? $t('domains.tags.type.parked.tooltip') : $t('domains.tags.type.active.tooltip')"
              />
            </template>
          </Column>

          <Column>
            <template #body="{ data }">
              <div class="flex flex-row-reverse items-center">
                <DomainsSettingsMenu
                  @show-logs="openLogsDrawer"
                  :key="data"
                  :is-text="true"
                  severity="contrast"
                  :domain="data"
                />
                <Link
                  :href="
                  route('ui.dmarc-settings.edit', {
                    customer: data.customer_uuid,
                    domain: data.uuid
                  })
                "
                >
                  <Button
                    text
                    v-tooltip.left="$t('domains.actions.edit_settings.tooltip')"
                    icon="pi pi-pencil"
                  />
                </Link>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </AppLayout>
</template>
