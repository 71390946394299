<script setup lang="ts">
import InnerCard from '@/Components/InnerCard.vue';
import Button from 'primevue/button';
import type { DomainResource } from '@/types/types.gen';
import EnableDkimSettingsModal from '@/Pages/Domains/DkimSettings/Partials/EnableDkimSettingsModal.vue';
import { reactive } from 'vue';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const state = reactive({
  showModal: false,
});
</script>

<template>
  <InnerCard class="mt-8">
    <template #content>
      <div
        class="mb-2 text-lg font-bold"
        v-text="$t('dkim_settings.enable.heading')"
      />
      <p v-text="$t('dkim_settings.enable.message')" />
    </template>
    <template #footer>
      <Button
        @click="state.showModal = true"
        type="submit"
        class="mt-3"
        severity="secondary"
        :label="$t('dkim_settings.enable.action')"
      />
    </template>
  </InnerCard>
  <div class="mt-8">
    <div class="text-lg font-bold" v-text="$t('dkim_settings.info.heading')" />
    <div class="mt-2">{{ $t('dkim_settings.info.message') }}</div>
  </div>
  <EnableDkimSettingsModal
    v-if="state.showModal"
    @hide="state.showModal = false"
    :domain="domain"
  />
</template>
