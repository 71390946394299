<script setup lang="ts">
import Button from 'primevue/button';
import ConfirmPopup from 'primevue/confirmpopup';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useConfirm } from 'primevue/useconfirm';

const { size = undefined } = defineProps<{ size?: 'small' | 'large' }>();

const emits = defineEmits(['delete']);

const confirm = useConfirm();
const { translate } = useTranslate();

const confirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLInputElement,
    message: translate('global.confirm_delete'),
    group: 'delete',
    icon: 'pi pi-info-circle',
    rejectProps: {
      label: translate('global.buttons.cancel'),
      severity: 'secondary',
      text: true,
    },
    acceptProps: {
      label: translate('global.buttons.delete'),
      severity: 'danger',
    },
    accept: () => {
      emits('delete');
    },
  });
};

</script>

<template>
  <ConfirmPopup group="delete" />
  <Button
    @click="confirmDelete($event)"
    :size="size"
    icon="pi pi-trash"
    text
    severity="danger"
    :label="$t('global.buttons.delete')"
  />
</template>
