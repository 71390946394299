<script setup lang="ts">
import type { BimiSetupInstructionsResource } from '@/types/types.gen';
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import ShowRawRecordButton from '@/Pages/Domains/DmarcSettings/Partials/ShowRawRecordButton.vue';

defineProps<{
  setupInstructions: BimiSetupInstructionsResource;
}>();

const rdType = 'BIMI';
</script>

<template>
    <SetupInstructionsCard
      title="BIMI"
      :description="$t('configurations.bimi.description')"
    >
      <div class="mb-2 mt-6 font-semibold">
        <div
             v-text="$t('configurations.instructions.add', { rdType })"
        />
      </div>

      <div class="bg-section-foreground p-8 flex flex-col gap-3 rounded">
        <SetupInstructions
          host="default._bimi"
          rd-type="CNAME"
          :value="setupInstructions?.bimi_cname_target ?? ''"
          show-copy-button
        />
        <ShowRawRecordButton v-if="setupInstructions.bimi_raw_record"
          :raw-record="setupInstructions.bimi_raw_record"
        />
      </div>
      <template #footer>
        <slot name="footer" />
      </template>
    </SetupInstructionsCard>
</template>
