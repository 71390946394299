<script setup lang="ts">
import Button from 'primevue/button';
import useCopyToClipboard from '@/Utils/useCopyToClipboard';

const { copyText = '', showText = true } = defineProps<{
  copyText?: string;
  showText?: boolean;
}>();

const { copy } = useCopyToClipboard();
</script>

<template>
  <div class="flex items-center">
    <div v-if="showText" v-text="copyText" />
    <Button
      text
      icon="pi pi-copy"
      class="!p-0"
      severity="primary"
      @click="copy(copyText)"
    />
  </div>
</template>
