<script setup lang="ts">

import Column from 'primevue/column';
import { useFormatters } from '@/Utils/Formatting';
import Legend from '@/Components/Legend.vue';
import type { ReportEmits, ReportProps } from '@/Pages/Statistics/Sender/types/reportsProps';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import IndexTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexTable.vue';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import IndexSubTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexSubTable.vue';
import ApprovedSenderVolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/ApprovedSenderVolumeCard.vue';
import ApprovedSenderIpsCard from '@/Pages/Statistics/Sender/Partials/ChartCards/ApprovedSenderIpsCard.vue';
import TopBlacklistedSendersCard from '@/Pages/Statistics/Sender/Partials/ChartCards/TopBlacklistedSendersCard.vue';
import { DailyStatisticsAggregator } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';
import { computed } from 'vue';

const { formatNumber } = useFormatters();

const { timelineStatistics } = defineProps<ReportProps>();
defineEmits<ReportEmits>();

const dailyStatistics = computed(() => new DailyStatisticsAggregator(timelineStatistics).groupedData);
</script>

<template>
  <div class="flex flex-row gap-5">
    <TopBlacklistedSendersCard :groups="groups"/>
    <ApprovedSenderVolumeCard :groups="groups" :daily-statistics="dailyStatistics"/>
    <ApprovedSenderIpsCard :groups="groups"/>
  </div>
  <IndexTable
    @change-category="(data, category) => $emit('change-category', data, category)"
    :customer-uuid="customerUuid"
    :domain="domain"
    :groups="groups"
    :ip-statistics-per-sender="ipStatisticsPerSender"
    default-sort-field="blacklistings_count"
  >
    <template #legend>
      <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.passing_dmarc')" :icon-class="['bg-graphs-positive']" />
      <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.failing_dmarc')" :icon-class="['bg-graphs-negative']" />
      <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.forward')" :icon-class="['bg-graphs-fill']" />
    </template>

    <template #columns>
      <Column class="w-1/6" :header="$t('senders_reports.senders.table.header.compliance')">
        <template #body="{data}: {data: GroupedStatistics}">
          <CompliancePercentageBar :data="data" />
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.volume')" sort-field="total_incidents" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_incidents) }}
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.blacklisted')" sort-field="total_blacklisted_incidents" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_blacklisted_incidents) }}
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.clean')" sort-field="total_clean_incidents" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_clean_incidents) }} <br>
        </template>
      </Column>
    </template>

    <template #sub-table="{data: {sender_identity}}: {data: GroupedStatistics}">
      <IndexSubTable
        :statistics="ipStatisticsPerSender.get(sender_identity)!"
        :aggregated-timeline-statistics="aggregatedTimelineStatistics"
        :sender-identity="sender_identity"
      >
        <template #columns>
          <Column sort-field="blacklistings_count" :sortable="true">
            <template #header>
              {{ $t('senders_reports.senders.table.header.blacklists') }}
              <div
                class="pi pi-info-circle"
                v-tooltip.top="$t('senders_reports.senders.table.info.blacklists')"
              />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(Object.values(data.blacklistings).length) }}
            </template>
          </Column>
          <Column :header="$t('senders_reports.senders.table.header.volume')" sort-field="total_incidents" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_incidents) }}
            </template>
          </Column>
          <Column :header="$t('senders_reports.senders.table.header.blacklisted')" sort-field="total_blacklisted_incidents" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_blacklisted_incidents) }}
            </template>
          </Column>
          <Column :header="$t('senders_reports.senders.table.header.clean')" sort-field="total_clean_incidents" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_clean_incidents) }}
            </template>
          </Column>
        </template>
      </IndexSubTable>
    </template>
  </IndexTable>
</template>
