<script setup lang="ts">
import { inject, ref } from 'vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import SelectButton from 'primevue/selectbutton';
import CountryFlag from '@/Components/CountryFlag.vue';
import type { MenuItem } from '@/Pages/Statistics/IpAddress/types';
import type { SenderIpAddressStatisticsResource } from '@/types/types.gen';
import { useFormatters } from '@/Utils/Formatting';

defineProps<{
  senderIpAddress: SenderIpAddressStatisticsResource;
  selected: MenuItem;
}>();

const { translate } = useTranslate();
const { formatDate } = useFormatters();

let dateRange = inject<[Date, Date]>('dateRage');

const emit = defineEmits<{
  (e: 'menu-item-changed', menuItem: MenuItem): void;
}>();

const items = ref([{
  label: translate('ip_address_modal.menu.overview'),
  value: <MenuItem>'overview',
}, {
  label: translate('ip_address_modal.menu.reporters'),
  value: <MenuItem>'reporters',
}, {
  label: translate('ip_address_modal.menu.results'),
  value: <MenuItem>'results',
}, {
  label: translate('ip_address_modal.menu.overrides'),
  value: <MenuItem>'overrides',
},
]);

const selectedMenuItem = ref<MenuItem>('overview');
</script>

<template>
  <div class="flex flex-col gap-2 mb-8" v-if="senderIpAddress">
    <div class="flex justify-between mr-1.5">
      <div class="flex items-center gap-6">
        <div>
          <div class="text-2xl font-semibold">
            {{  senderIpAddress.ip_address }}
          </div>
          <div class="text-lg">
            {{ senderIpAddress.organization }}
          </div>
        </div>
        <CountryFlag
          :code="senderIpAddress.country"
          class="text-4xl"
        />
      </div>
      <div class="flex flex-col">
        <div class="text-right font-semibold">{{ $t('ip_address_modal.header.date_range')}}</div>
        <div>{{ dateRange ? formatDate(dateRange[0]) : '' }} {{ $t('ip_address_modal.header.to') }} {{ dateRange ? formatDate(dateRange[1]) : '' }}</div>
      </div>
    </div>
    <SelectButton
      :options="items"
      v-model="selectedMenuItem"
      @change="emit('menu-item-changed', selectedMenuItem)"
      class="mt-6"
      optionLabel="label"
      optionValue="value"
      :allow-empty="false"
    />
  </div>
</template>
