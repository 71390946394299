<script setup lang="ts">

import Column from 'primevue/column';
import { useFormatters } from '@/Utils/Formatting';
import Legend from '@/Components/Legend.vue';
import ComplianceCard from '@/Pages/Statistics/Sender/Partials/ChartCards/ComplianceCard.vue';
import VolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/VolumeCard.vue';
import SendersCard from '@/Pages/Statistics/Sender/Partials/ChartCards/SendersCard.vue';
import IndexTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexTable.vue';
import ComplianceLegend from '@/Pages/Statistics/Sender/Partials/ComplianceLegend.vue';
import CompliancePercentageBar from '@/Pages/Statistics/Sender/Charts/CompliancePercentageBar.vue';
import IndexSubTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexSubTable.vue';
import type { ReportEmits, ReportProps } from '@/Pages/Statistics/Sender/types/reportsProps';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';

const { formatNumber } = useFormatters();
defineProps<ReportProps>();
defineEmits<ReportEmits>();
</script>

<template>
  <div class="flex flex-col xl:flex-row gap-8">
    <ComplianceCard :data="groups" />
    <VolumeCard :groups="groups" :timeline-statistics="timelineStatistics ?? []" />
    <SendersCard :groups="groups" :timeline-statistics="timelineStatistics ?? []" />
  </div>
  <IndexTable
    @change-category="(data, category) => $emit('change-category', data, category)"
    :customer-uuid="customerUuid"
    :domain="domain"
    :groups="groups"
    :ip-statistics-per-sender="ipStatisticsPerSender"
  >
    <template #legend>
      <ComplianceLegend />
    </template>

    <template #columns>
      <Column class="w-1/6" :header="$t('senders_reports.senders.table.header.compliance')">
        <template #body="{data}: {data: GroupedStatistics}">
          <CompliancePercentageBar :data="data" />
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.volume')" field="total_incidents" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_incidents) }}
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.passing')" field="total_complete_passes" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_passing) }}
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.failing')" field="total_complete_failures" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_failing) }}
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.forwards')" field="total_forwards" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_forwards) }}
        </template>
      </Column>
    </template>

    <template #sub-table="{data: {sender_identity}}: {data: GroupedStatistics}">
      <IndexSubTable
        :groups="ipStatisticsPerSender.get(sender_identity) ?? []"
        :aggregated-timeline-statistics="aggregatedTimelineStatistics"
        :sender-identity="sender_identity"
      >
        <template #legend>
          <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.passing_dkim_spf')" :icon-class="['bg-graphs-positive']" />
          <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.passing_dkim')" :icon-class="['bg-graphs-warning']" />
          <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.passing_spf')" :icon-class="['bg-graphs-color1']" />
        </template>

        <template #columns>
          <Column :header="$t('senders_reports.senders.table.header.volume')" sort-field="total_incidents" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_incidents) }}
            </template>
          </Column>
          <Column :header="$t('senders_reports.senders.table.header.passing')" sort-field="total_compliant" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_passing) }}
            </template>
          </Column>
          <Column sort-field="total_complete_passes" :sortable="true" class="passing-breakdown">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-positive']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_complete_passes) }}
            </template>
          </Column>
          <Column sort-field="total_spf_fails_dkim_passes" :sortable="true" class="passing-breakdown">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-warning']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_spf_fails_dkim_passes) }}
            </template>
          </Column>
          <Column sort-field="total_dkim_fails_spf_passes" :sortable="true" class="passing-breakdown">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-color1']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_dkim_fails_spf_passes) }}
            </template>
          </Column>
          <Column :header="$t('senders_reports.senders.table.header.failing')" sort-field="total_failing" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_failing) }}
            </template>
          </Column>
          <Column :header="$t('senders_reports.senders.table.header.forwards')" sort-field="total_forwards" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_forwards) }}
            </template>
          </Column>
        </template>
      </IndexSubTable>
    </template>
  </IndexTable>
</template>

<style>
th.passing-breakdown,td.passing-breakdown {
 background: var(--p-section-foreground);
}
</style>
