<script setup lang="ts">
import { SenderCategory } from '@/types/types.gen';
import SelectButton from 'primevue/selectbutton';
import { ref } from 'vue';

const { currentCategory } = defineProps<{ currentCategory: SenderCategory }>();

const selected = ref<SenderCategory>(currentCategory);

const emit = defineEmits<{
  (e: 'menu-item-changed', menuItem: SenderCategory): void;
}>();

</script>

<template>
  <SelectButton
    :allow-empty="false"
    v-model="selected"
    @change="emit('menu-item-changed', selected)"
    :options="[
      { label: $t('senders_reports.categories.all'), value: SenderCategory.ALL },
      { label: $t('senders_reports.categories.authorised_compliant'), value: SenderCategory.AUTHORISED_COMPLIANT },
      { label: $t('senders_reports.categories.forwarder'), value: SenderCategory.FORWARDER },
      { label: $t('senders_reports.categories.unauthorised'), value: SenderCategory.UNAUTHORISED },
      { label: $t('senders_reports.categories.suspicious'), value: SenderCategory.SUSPICIOUS },
      { label: $t('senders_reports.categories.unknown'), value: SenderCategory.UNKNOWN },
    ]"
    optionLabel="label"
    optionValue="value"
  />
</template>
