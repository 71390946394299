<script setup lang="ts">
import type { DomainResource } from '@/types/types.gen';
import DomainPolicyChart from '@/Pages/Statistics/Dashboard/Charts/DomainOverviewChart.vue';
import { Link, usePage } from '@inertiajs/vue3';
import ChartCard from '@/Components/Cards/ChartCard.vue';

const { domains } = defineProps<{
  domains: DomainResource[];
}>();

const { activeCustomer } = usePage().props;

const totalReportingDomains
  = domains.filter(domain => domain.first_report_date !== null).length;

</script>

<template>
  <ChartCard :title="$t('dashboard.cards.domain_totals.heading')">
    <template #lead>
      <div class="text-2xl">
        {{ `${totalReportingDomains}  ${$t('dashboard.cards.domain_totals.reporting')}` }}
      </div>
      <div class="mb-1.5 mt-0.5">
        {{ `${domains.length} ${$t('dashboard.cards.domain_totals.total')}` }}
      </div>

      <Link
        :href="route('ui.domains.index', activeCustomer.uuid)"
        class="text-primary-default text-lg"
      >
        {{ $t('dashboard.cards.domain_totals.action')}}
      </Link>
    </template>
    <template #graph>
      <DomainPolicyChart
        class="mt-2"
        :domains="domains"
      />
    </template>
  </ChartCard>
</template>
