<script setup lang="ts">
import { type AlertsResource, AlertType } from '@/types/types.gen';
import { inject, reactive } from 'vue';
import type { InertiaForm } from '@inertiajs/vue3';
import Button from 'primevue/button';
import { AlertCategory, type AlertConfig, useAlertConfig } from '@/Pages/Alerts/config';
import { useTranslate } from '@/Utils/Translations/useTranslate';
const { translate } = useTranslate();

const emits = defineEmits<{
  (e: 'completeStep', step: string): void;
}>();

const form: InertiaForm<AlertsResource> = inject('form')!;

const state = reactive({
  activity_type: 'domain',
});

const config = useAlertConfig();

const getCategorizedAlertsConfig = () => {
  const categorizedAlerts: Record<string, AlertConfig[]> = config.reduce((alert, alertConfig) => {
    if (!alert[alertConfig.category] && alertConfig.group === state.activity_type) {
      alert[alertConfig.category] = [];
    }

    if (alertConfig.group === state.activity_type) {
      alert[alertConfig.category].push(alertConfig);
    }

    return alert;
  }, {} as Record<string, AlertConfig[]>);

  return categorizedAlerts;
};

const selectAlert = (type: AlertType) => {
  form.type = type;
  emits('completeStep', 'activity');
};

const getCategory = (category: string) => {
  return translate(`alerts.activity.categories.${(<AlertCategory>category)}`);
};

const selectedAlertClass = (type: string) => {
  if (form.type === type) {
    return 'bg-highlight-default';
  }

  return 'bg-section-default';
};

</script>
<template>
  <div class="flex flex-col gap-8 px-5 py-4">
    <div class="font-medium">{{ $t('alerts.activity.description') }}</div>

    <div class="alert-types flex flex-col gap-8">
      <div v-for="(alertsConfig, category) in getCategorizedAlertsConfig()" :key="category" class="flex flex-col gap-2">
        <div v-if="category !== 'none'" class="text-sm">{{ getCategory(category) }}</div>

        <div v-for="(alertConfig, key) in alertsConfig" class="flex px-6 py-5 rounded justify-between" :class="selectedAlertClass(alertConfig.type)" :key="key">
          <div class="flex flex-col gap-1">
            <div>{{ $t(`alerts.activity.options.${alertConfig.type}.heading`) }}</div>
            <div class="text-sm">{{ alertConfig.description }}</div>
          </div>
          <div class="flex flex-col">
            <Button @click="selectAlert(alertConfig.type)" class="m-auto mr-3" severity="secondary" :label="$t('global.buttons.select')" text>{{ $t('global.buttons.select') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
