<script setup lang="ts">
import Message from 'primevue/message';
import ShowRawRecordButton from '@/Pages/Domains/DmarcSettings/Partials/ShowRawRecordButton.vue';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';
import type {
  DmarcSetupInstructionsResource,
} from '@/types/types.gen';
import type { Dmarc } from '@/types/nightcrawler.types.gen';

defineProps<{
  dnsRecord: Dmarc | null;
  setupInstructions: DmarcSetupInstructionsResource;
}>();

const rdType = 'DMARC';
</script>

<template>
  <SetupInstructionsCard
    :title="rdType"
    :description="$t('configurations.dmarc.description')"
  >
    <template #header>
      <Message
        v-if="dnsRecord"
        icon="pi pi-exclamation-triangle"
        closable
        severity="warn"
        class="mb-8"
      >
        {{ $t('configurations.existing_dns_record_found', {record: rdType}) }}
      </Message>
    </template>

    <div v-if="dnsRecord">
      <div class="mb-2 font-semibold"
         v-text="$t('configurations.instructions.remove', { rdType })"
      />
      <div class="bg-section-foreground p-8 flex flex-col gap-3 rounded">
        <SetupInstructions
          host="_dmarc"
          :rd-type="dnsRecord.rdtype!"
          :value="dnsRecord.entries[0]"
          :show-copy-button="false"
        />
      </div>
    </div>

    <div class="mb-2 mt-6 font-semibold">
      <div v-if="dnsRecord"
         v-text="$t('configurations.instructions.new', { rdType })"
      />
      <div v-else
         v-text="$t('configurations.instructions.add', { rdType })"
      />
    </div>

    <div class="bg-section-foreground p-8 flex flex-col gap-3 rounded">
      <SetupInstructions
        host="_dmarc"
        rd-type="CNAME"
        :value="setupInstructions.dmarc_host"
        show-copy-button
      />
      <ShowRawRecordButton
        :raw-record="setupInstructions.dmarc_raw_record"
      />
    </div>
    <div class="mt-2 text-sm"
       v-text="$t('configurations.dmarc.warning')"
    />
    <template #footer>
      <slot name="footer"/>
    </template>
  </SetupInstructionsCard>
</template>
