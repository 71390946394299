<script setup lang="ts">
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { getCssVariable } from '@/Utils/Styling';
import type { ChartData } from 'chart.js';
import type { TimelineStatisticsResource } from '@/types/types.gen';
import BarChart from '@/Components/Charts/BarChart.vue';
import { DailyStatisticsAggregator } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';
import { computed, type ComputedRef } from 'vue';

const { translate } = useTranslate();
const { data } = defineProps<{ data: TimelineStatisticsResource[] }>();
const dailyStatistics = computed(() => new DailyStatisticsAggregator(data).groupedData);

const chartData: ComputedRef<ChartData<'bar'>> = computed(() => ({
  labels: Object.keys(dailyStatistics.value),
  datasets: [
    {
      label: translate('senders_reports.senders.legends.senders'),
      data: Object.values(dailyStatistics.value).map(stats => stats.total_incidents),
      backgroundColor: getCssVariable('--p-graphs-positive'),
      borderRadius: 3,
    },
  ],
}));
</script>

<template>
  <BarChart :data="chartData" />
</template>
