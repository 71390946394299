<script setup lang="ts">

import 'leaflet/dist/leaflet.css';
import 'vue-map-ui/dist/style.css';
import 'vue-map-ui/dist/theme-all.css';

import CountryCoordinates from '@/Components/Map/CountryCoordinates';
import type { CountryTotalsResource } from '@/types/types.gen';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Legend from '@/Components/Legend.vue';
import { useFormatters } from '@/Utils/Formatting';
import { orderBy } from 'lodash';
import { VMap, VMapMarker, VMapOsmTileLayer, VMapPinIcon, VMapZoomControl } from 'vue-map-ui';
import { getCssVariable } from '@/Utils/Styling';

const { countryTotals } = defineProps<{
  countryTotals: CountryTotalsResource[];
}>();

const countries = orderBy(countryTotals, 'aggregates.total_incidents', 'desc')
  .filter(c => c.country in CountryCoordinates)
  .slice(0, 5);

const colours = [
  { class: 'bg-primary-default', color: getCssVariable('--p-primary-600') },
  { class: 'bg-graphs-positive', color: getCssVariable('--p-graphs-positive') },
  { class: 'bg-warn-default', color: getCssVariable('--p-warn-default') },
  { class: 'bg-graphs-negative', color: getCssVariable('--p-graphs-negative') },
  { class: 'bg-graphs-fill', color: getCssVariable('--p-graphs-fill') },
];

const { formatNumber } = useFormatters();
</script>

<template>
  <Card>
    <template #content>
      <div
        class="font-semibold text-lg mb-3"
        v-text="$t('exposure_report.top_countries.heading')"
      />

      <VMap :zoom="1" style="height: 260px">
        <VMapOsmTileLayer />
        <VMapZoomControl />
        <VMapMarker
          v-for="(country, index) in countries"
          :key="country.country"
          :latlng="CountryCoordinates[country.country as keyof typeof CountryCoordinates]"
        >
          <VMapPinIcon
            :color="colours[index % colours.length].color"
          >
            {{ country.country }}
          </VMapPinIcon>
        </VMapMarker>
      </VMap>

      <DataTable :value="countries">
        <Column>
          <template #body="{ data, index }">
            <Legend
              class="gap-3"
              :icon-size="3"
              :text="data.country_name"
              :icon-class="[colours[index % colours.length].class]"
            />
          </template>
        </Column>
        <Column class="flex flex-row-reverse">
          <template #body="{ data }">
            {{ formatNumber(data.aggregates.total_incidents) }}
          </template>
        </Column>
      </DataTable>

    </template>
  </Card>
</template>
