<script setup lang="ts">
import type { InertiaForm } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import DirectivesPanel from '@/Pages/Domains/SpfSettings/Partials/DirectivesPanel.vue';
import SettingsPanel from '@/Pages/Domains/SpfSettings/Partials/SettingsPanel.vue';
import { provide } from 'vue';
import responseCallbacks from '@/Utils/ResponseCallbacks';
import SaveAndExit from '@/Components/SaveAndExit.vue';
import { useRoute } from 'ziggy-js';
import type {
  DnsDelegationVerificationResource,
  DomainResource,
  SpfDirectiveResource,
  SpfSettingResource,
} from '@/types/types.gen';
import type { PaginatedResponse } from '@/types/http';
import { DomainSettingsTab } from '@/Pages/Domains/types/DomainSettings';
import DomainsSettingsLayout from '@/Pages/Domains/DomainSettingsLayout.vue';
import LinkSetupInstructions from '@/Pages/Domains/Partials/LinkSetupInstructions.vue';
import type { SpfSettingsForm } from '@/Pages/Domains/SpfSettings/types';
import type { SaveOrExitAction } from '@/Pages/Domains/types';
import type { DomainScore } from '@/types/cyclops.types.gen';
import VerificationStatus from '@/Components/VerificationStatus.vue';

const { directives, settings, domain, verification } = defineProps<{
  directives: PaginatedResponse<SpfDirectiveResource>;
  settings?: SpfSettingResource | null;
  domain: DomainResource;
  cyclopsScore: DomainScore | null;
  verification?: DnsDelegationVerificationResource;
}>();

const form: InertiaForm<SpfSettingsForm> = useForm({
  directives: directives.data,
  enable_flattening: settings?.enable_flattening ?? false,
  all_mechanism_qualifier: settings?.all_mechanism_qualifier ?? '-',
});

provide('form', form);

const submit = (action: SaveOrExitAction) => {
  const route = useRoute();
  const updateRoute = route('ui.spf-settings.update', {
    customer: domain.customer_uuid,
    domain: domain.uuid,
    action,
  });

  form.put(updateRoute, responseCallbacks);
};
</script>

<template>
  <DomainsSettingsLayout
    :title="$t('spf_settings.title')"
    :domain="domain"
    :tab="DomainSettingsTab.SPF"
    :cyclops-score="cyclopsScore"
  >
    <div class="my-5 flex flex-row justify-between">
      <VerificationStatus v-if="verification" :verification="verification"/>
      <LinkSetupInstructions :domain="domain" href="ui.spf-settings.setup"/>
    </div>
    <div>
      <DirectivesPanel :domain="domain" />
      <SettingsPanel />
    </div>

    <template #footer>
      <SaveAndExit
        :cancel-route="route('ui.domains.index', [domain.customer_uuid])"
        @submit="submit"
      />
    </template>
  </DomainsSettingsLayout>
</template>
