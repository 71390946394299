<script setup lang="ts">

import Column from 'primevue/column';
import { useFormatters } from '@/Utils/Formatting';
import Legend from '@/Components/Legend.vue';
import type { ReportEmits, ReportProps } from '@/Pages/Statistics/Sender/types/reportsProps';
import DeliverabilityCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverabilityCard.vue';
import DeliverableVolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/DeliverableVolumeCard.vue';
import type { GroupedStatistics } from '@/Pages/Statistics/Sender/types';
import IndexTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexTable.vue';
import DeliverabilityPercentageBar from '@/Pages/Statistics/Sender/Charts/DeliverabilityPercentageBar.vue';
import IndexSubTable from '@/Pages/Statistics/Sender/Partials/Tables/IndexSubTable.vue';
import BlockedVolumeCard from '@/Pages/Statistics/Sender/Partials/ChartCards/BlockedVolumeCard.vue';
import { computed } from 'vue';
import { DailyStatisticsAggregator } from '@/Pages/Statistics/Sender/Helpers/DataAggregators';

const { formatNumber } = useFormatters();

const { timelineStatistics } = defineProps<ReportProps>();
defineEmits<ReportEmits>();

const dailyStatistics = computed(() => new DailyStatisticsAggregator(timelineStatistics).groupedData);
</script>

<template>
  <div class="flex flex-row gap-5">
    <DeliverabilityCard :data="groups"/>
    <DeliverableVolumeCard :daily-statistics="dailyStatistics"/>
    <BlockedVolumeCard :daily-statistics="dailyStatistics"/>
  </div>
  <IndexTable
    @change-category="(data, category) => $emit('change-category', data, category)"
    :customer-uuid="customerUuid"
    :domain="domain"
    :groups="groups"
    :ip-statistics-per-sender="ipStatisticsPerSender"
  >
    <template #legend>
      <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.delivered')" :icon-class="['bg-graphs-positive']" />
      <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.overridden_as_delivered')" :icon-class="['bg-graphs-color1']" />
      <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.not_delivered')" :icon-class="['bg-graphs-negative']" />
      <Legend :icon-size="2" :text="$t('senders_reports.senders.legends.overridden_as_not_delivered')" :icon-class="['bg-graphs-warning']" />
    </template>

    <template #columns>
      <Column class="w-1/6" :header="$t('senders_reports.senders.table.header.deliverability')">
        <template #body="{data}: {data: GroupedStatistics}">
          <DeliverabilityPercentageBar :data="data" />
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.volume')" field="total_incidents" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_incidents) }}
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.delivered')" field="total_delivered" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_delivered) }} <br>
        </template>
      </Column>
      <Column :header="$t('senders_reports.senders.table.header.not_delivered')" field="total_not_delivered" :sortable="true">
        <template #body="{data}: {data: GroupedStatistics}">
          {{ formatNumber(data.total_not_delivered) }} <br>
        </template>
      </Column>
    </template>

    <template #sub-table="{data: {sender_identity}}: {data: GroupedStatistics}">
      <IndexSubTable
        :statistics="ipStatisticsPerSender.get(sender_identity)!"
        :aggregated-timeline-statistics="aggregatedTimelineStatistics"
        :sender-identity="sender_identity"
      >
        <template #columns>
          <Column :header="$t('senders_reports.senders.table.header.volume')" sort-field="total_incidents" :sortable="true">
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_incidents) }}
            </template>
          </Column>
          <Column sort-field="total_delivered" :sortable="true" class="delivery-breakdown">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-positive']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_delivered) }}
            </template>
          </Column>
          <Column sort-field="total_permitted" :sortable="true" class="delivery-breakdown">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-color1']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_permitted) }}
            </template>
          </Column>
          <Column sort-field="total_complete_failures" :sortable="true" class="delivery-breakdown">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-negative']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_complete_failures) }}
            </template>
          </Column>
          <Column sort-field="total_blocked" :sortable="true" class="delivery-breakdown">
            <template #header>
              <Legend :icon-size="2" text="" :icon-class="['bg-graphs-warning']" />
            </template>
            <template #body="{data}: {data: GroupedStatistics}">
              {{ formatNumber(data.total_blocked) }}
            </template>
          </Column>
        </template>
      </IndexSubTable>
    </template>
  </IndexTable>
</template>

<style>
th.delivery-breakdown,td.delivery-breakdown {
 background: var(--p-section-foreground);
}
</style>
