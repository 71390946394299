<script setup lang="ts">
import InnerCard from '@/Components/InnerCard.vue';
import Button from 'primevue/button';
import type { DomainResource } from '@/types/types.gen';
import { reactive } from 'vue';
import EnableMtaSettingsModal from '@/Pages/Domains/TlsRptSettings/Partials/EnableMtaSettingsModal.vue';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const state = reactive({
  showModal: false,
});
</script>

<template>
  <InnerCard>
    <template #content>
      <div
        class="mb-2 text-lg font-bold"
        v-text="$t('mta_settings.enable.heading')"
      />
      <p v-text="$t('mta_settings.enable.message')" />
    </template>
    <template #footer>
      <Button
        @click="state.showModal = true"
        type="submit"
        class="mt-3"
        severity="secondary"
        :label="$t('mta_settings.enable.action')"
      />
    </template>
  </InnerCard>
  <EnableMtaSettingsModal
    v-if="state.showModal"
    @hide="state.showModal = false"
    :domain="domain"
  />
</template>
