<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useRoute } from 'ziggy-js';
import { router } from '@inertiajs/vue3';
import type { DomainResource } from '@/types/types.gen';
import { ref } from 'vue';
import responseCallbacks from '@/Utils/ResponseCallbacks';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const emits = defineEmits(['hide']);

const enableDkimSettings = () => {
  const route = useRoute()('ui.dkim-settings.update', {
    customer: domain.customer_uuid,
    domain: domain.uuid,
  });

  router.put(
    route,
    { is_delegation_enabled: true },
    {
      ...responseCallbacks,
      preserveState: false,
    },
  );
};

const visible = ref(true);
</script>

<template>
  <Dialog
    v-model:visible="visible"
    @hide="emits('hide')"
    modal
    :header="
      $t('dkim_settings.enable.modal.header', { domain: domain.domain_name })
    "
    class="w-2/6"
  >
    {{ $t('dkim_settings.enable.modal.message') }}

    <template #footer>
      <div class="flex items-center gap-4">
        <Button
          @click="visible = false"
          link
          :label="$t('global.buttons.cancel')"
          type="submit"
        />

        <form @submit.prevent="enableDkimSettings">
          <Button :label="$t('dkim_settings.enable.action')" type="submit" />
        </form>
      </div>
    </template>
  </Dialog>
</template>
