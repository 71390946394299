<script setup lang="ts">
import { type DnsDelegationVerificationResource } from '@/types/types.gen';
import { DnsDelegationVerificationResult } from '@/types/types.gen';
import { formatDate } from 'date-fns';
import { computed } from 'vue';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { verification, verifiedText, pendingVerificationText } = defineProps<{
  verification: DnsDelegationVerificationResource;
  verifiedText?: string;
  pendingVerificationText?: string;
}>();

const { translate } = useTranslate();

const result = computed(() => {
  const verified = {
    text: verifiedText ?? translate ('configurations.status.verified'),
    textClasses: 'text-success-default',
    iconClasses: 'pi-verified text-success-default',
  };
  const pendingVerification = {
    text: pendingVerificationText ?? translate ('configurations.status.pending'),
    textClasses: 'text-danger-default',
    iconClasses: 'pi-times-circle text-danger-default',
  };

  return {
    [DnsDelegationVerificationResult.PASS]: verified,
    [DnsDelegationVerificationResult.PARTIAL]: verified,
    [DnsDelegationVerificationResult.WARNING]: pendingVerification,
    [DnsDelegationVerificationResult.ERROR]: pendingVerification,
  }[verification.result] || pendingVerification;
});

</script>

<template>
  <div class="flex items-center">
    <i
      class="pi mr-3 !text-2xl"
      :class="result.iconClasses"
    ></i>
    <div>
      <span class="font-bold" :class="result.textClasses"> {{ result.text }}</span>
      <span class="text-sm"> - {{ $t('configurations.status.last_date') }} {{ formatDate(new Date(verification.updated_at), 'yyyy-MM-dd hh:mm') }} </span>
    </div>
  </div>
</template>
