import { ref } from 'vue';
import type { SenderIpAddressStatisticsResource } from '@/types/types.gen';

const isModalOpen = ref(false);
const senderIpAddress = ref<SenderIpAddressStatisticsResource | null>(null);

export const useIpStatisticsModal = () => {
  const openModal = (data: SenderIpAddressStatisticsResource) => {
    isModalOpen.value = true;
    senderIpAddress.value = data;
  };

  const closeModal = () => {
    isModalOpen.value = false;
    senderIpAddress.value = null;
  };

  return {
    isModalOpen,
    senderIpAddress,
    openModal,
    closeModal,
  };
};
