<script setup lang="ts">
import FormGroup from '@/Components/FormGroup.vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { router } from '@inertiajs/vue3';
import { reactive, ref } from 'vue';
import type { DomainResource } from '@/types/types.gen';
import Select from 'primevue/select';
import axios from 'axios';
import { useRoute } from 'ziggy-js';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import useCopyToClipboard from '@/Utils/useCopyToClipboard';

const { domain } = defineProps<{
  domain: DomainResource;
}>();

const { copy } = useCopyToClipboard();

const isVisible = ref(false);

const state = reactive({
  expiry: 86400,
  setupInstructionsUrl: null,
});

const generateLink = () => {
  const route = useRoute();

  axios
    .get(
      route('api.setup-instructions.generate', {
        customer: domain.customer_uuid,
        domain: domain.uuid,
        expiry: state.expiry,
      }),
    )
    .then((response) => {
      state.setupInstructionsUrl = response.data.url;
    });
};

const toggleModel = () => isVisible.value = !isVisible.value;

const openPublicSetupInstructionsPage = () => {
  if (state.setupInstructionsUrl) {
    window.open(state.setupInstructionsUrl, '_blank')
  }
}

</script>

<template>
  <div>
    <slot
      name="default"
      :toggle="toggleModel"
    />

    <Dialog
      v-model:visible="isVisible"
      @hide="
        router.get(
          route('ui.domain.index', {
            customer: domain.customer_uuid,
            domain: domain.uuid
          })
        )
      "
      modal
      :header="$t('domains.setup_modal.header')"
      class="w-2/6"
    >
      <FormGroup
        v-if="!state.setupInstructionsUrl"
        :label="$t('domains.setup_modal.fields.expire_link.input_label')"
      >
        <template v-slot:element="slotProps">
          <Select
            class="w-1/3"
            v-model="state.expiry"
            v-bind="slotProps"
            :options="[
              { value: 21600, text: $t('domains.setup_modal.fields.expire_link.options.6_hours') },
              { value: 43200, text: $t('domains.setup_modal.fields.expire_link.options.12_hours') },
              { value: 86400, text: $t('domains.setup_modal.fields.expire_link.options.1_day') },
              { value: 604800, text: $t('domains.setup_modal.fields.expire_link.options.1_week') },
              { value: 2592000, text: $t('domains.setup_modal.fields.expire_link.options.1_month') },
              { value: 16777215, text: $t('domains.setup_modal.fields.expire_link.options.never') }
            ]"
            optionLabel="text"
            optionValue="value"
          />
        </template>
      </FormGroup>

      <FormGroup
        v-if="state.setupInstructionsUrl"
        :label="$t('domains.setup_modal.fields.copy_link.input_label')"
      >
        <template #element>
          <InputGroup>
            <InputText :model-value="state.setupInstructionsUrl" />
            <Button @click="copy(state.setupInstructionsUrl)"
              icon="pi pi-copy"
              severity="secondary"
            />
          </InputGroup>
        </template>
      </FormGroup>

      <template #footer>
        <div class="flex items-center justify-between gap-2">
          <form v-if="!state.setupInstructionsUrl" @submit.prevent="generateLink" >
            <Button :label="$t('domains.setup_modal.action')" type="submit" />
          </form>
          <Button
            v-else
            @click="openPublicSetupInstructionsPage"
            :label="$t('global.buttons.open_link')"
            icon="pi pi-arrow-up-right"
            icon-pos="right"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>
