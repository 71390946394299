<script setup lang="ts">
import ShowRawRecordButton from '@/Pages/Domains/DmarcSettings/Partials/ShowRawRecordButton.vue';
import SetupInstructions from '@/Pages/Domains/Partials/SetupInstructions.vue';
import type {
  SpfSetupInstructionsResource,
} from '@/types/types.gen';
import SetupInstructionsCard from '@/Pages/Domains/Partials/SetupInstructionsCard.vue';
import type { Spf } from '@/types/nightcrawler.types.gen';
import Message from 'primevue/message';

defineProps<{
  dnsRecord: Spf | null;
  setupInstructions: SpfSetupInstructionsResource;
}>();

const rdType = 'SPF';
</script>

<template>
  <SetupInstructionsCard
    :title="rdType"
    :description="$t('configurations.spf.description')"
  >
    <template #header>
      <Message
        v-if="dnsRecord"
        icon="pi pi-exclamation-triangle"
        closable
        severity="warn"
        class="mb-8"
      >
        {{ $t('configurations.existing_dns_record_found', {record: rdType}) }}
      </Message>
    </template>

    <div v-if="dnsRecord">
      <div class="mb-1 mt-6 font-semibold">
        {{ $t('configurations.instructions.remove', { rdType }) }}
      </div>
      <div class="bg-section-foreground p-8 flex flex-col gap-3 rounded">
        <SetupInstructions
          host="@"
          :rd-type="dnsRecord.rdtype!"
          :value="dnsRecord.entries[0]"
          :show-copy-button="false"
        />
      </div>
    </div>

    <div class="mb-1 mt-6 font-semibold">
      <div v-if="dnsRecord">
        {{ $t('configurations.instructions.new', { rdType }) }}
      </div>
      <div v-else>
        {{ $t('configurations.instructions.add', { rdType }) }}
      </div>
    </div>
    <div class="bg-section-foreground p-8 flex flex-col gap-3 rounded">
      <SetupInstructions
        host="@"
        rd-type="TXT"
        :value="`v=spf1 redirect=${setupInstructions.spf_host}`"
        show-copy-button
      />
      <ShowRawRecordButton :raw-record="setupInstructions.spf_raw_record" />
    </div>
    <div class="mt-2 text-sm">
      {{ $t('configurations.dmarc.warning') }}
    </div>

    <template #footer>
      <slot name="footer" />
    </template>
  </SetupInstructionsCard>
</template>
