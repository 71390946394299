<script setup lang="ts">
import { router } from '@inertiajs/vue3';
import Button from 'primevue/button';
import { useTranslate } from '@/Utils/Translations/useTranslate';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';
import { type DnsDelegationVerificationResource } from '@/types/types.gen';
import { type TranslationKey } from '@/Utils/Translations/types';

const { path, verification } = defineProps<{
  path: string;
  verification: DnsDelegationVerificationResource;
}>();

const { translate } = useTranslate();
const toast = useToast();
const verificationInProgress = ref(false);

const showError = (error: string) => {
  toast.add({
    severity: 'error',
    summary: error,
    detail: verification.messages.error?.map(error => translate(error as TranslationKey)).join('\n'),
    life: 5000,
  });
  verificationInProgress.value = false;
}

const verify = () => {
  verificationInProgress.value = true;
  router.post(path, {}, {
    onSuccess: (data) => {
      if (['pass', 'partial'].includes(data.props.verification?.result ?? '')) {
        toast.add({
          severity: 'success',
          summary: translate('delegation-verifications.verify.success'),
          life: 5000,
        });
        return;
      }

      verificationInProgress.value = false;
    },
    onError: (error) => {
      showError(Object.values(error)[0]);
    },
    preserveScroll: true,
  });
}
</script>

<template>
  <Button
    @click="verify"
    :disabled="verificationInProgress"
    label='Verify Now'
    severity="secondary"
  />
</template>

<style scoped>

</style>
