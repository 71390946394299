<script setup lang="ts">
import Drawer from 'primevue/drawer';
import { useIpStatisticsModal } from '@/Pages/Statistics/IpAddress/Utils/useIpStatisticsModal';
import DrawerHeader from '@/Pages/Statistics/IpAddress/Partials/DrawerHeader.vue';
import Reporters from '@/Pages/Statistics/IpAddress/Partials/Reporters.vue';
import Results from '@/Pages/Statistics/IpAddress/Partials/Results.vue';
import Overview from '@/Pages/Statistics/IpAddress/Partials/Overview.vue';
import { ref, watch } from 'vue';
import type { MenuItem } from '@/Pages/Statistics/IpAddress/types';
import axios from 'axios';
import { useRoute } from 'ziggy-js';
import type { AggregateReportResource, DomainResource } from '@/types/types.gen';
import Overrides from '@/Pages/Statistics/IpAddress/Partials/Overrides.vue';
import type { DateRange } from '@/Pages/Statistics/Sender/types';
import { format } from 'date-fns';

const { domain, dateRange } = defineProps<{
  domain: DomainResource;
  dateRange: DateRange;
}>();

const { isModalOpen, senderIpAddress } = useIpStatisticsModal();

const route = useRoute();

const selectedMenuItem = ref<MenuItem>('overview');

const aggregateReports = ref<AggregateReportResource[]>([]);

watch(isModalOpen, async (newValue) => {
  if (newValue && senderIpAddress.value) {
    const result = await axios.get(route('api.statistics.ip-address.reports', {
      domain: domain.uuid,
      sender_ip_address: senderIpAddress.value.uuid,
      start_date: format(dateRange.start_date, 'yyyy-LL-dd'),
      end_date: format(dateRange.end_date, 'yyyy-LL-dd'),
    }));
    aggregateReports.value = result.data;
  }

  if (!newValue) {
    aggregateReports.value = [];
    selectedMenuItem.value = 'overview';
  }
});

</script>

<template>
  <Drawer
    v-if="senderIpAddress"
    v-model:visible="isModalOpen"
    position="right"
    closeIcon="pi pi-sign-out"
    class="!w-full !bg-ground-background"
  >
    <div class="mx-auto max-w-screen-2xl">
      <DrawerHeader
        :senderIpAddress="senderIpAddress"
        :selected="selectedMenuItem"
        @menu-item-changed="(menuItem) => selectedMenuItem = menuItem"
      />
      <Overview
        v-if="selectedMenuItem === 'overview'"
        :senderIpAddress="senderIpAddress"
      />
      <Reporters
        v-if="selectedMenuItem === 'reporters'"
        :aggregateReports="aggregateReports"
        :senderIpAddress="senderIpAddress"
      />
      <Results
        v-if="selectedMenuItem === 'results'"
        :aggregateReports="aggregateReports"
      />
      <Overrides
        v-if="selectedMenuItem === 'overrides'"
        :aggregate-reports="aggregateReports"
        :senderIpAddress="senderIpAddress"
      />
    </div>
  </Drawer>
</template>
