<script setup lang="ts">
import Tag from 'primevue/tag';
import type { DmarcPolicy } from '@/types/types.gen';
import { useTranslate } from '@/Utils/Translations/useTranslate';

const { translate } = useTranslate();
const { policy } = defineProps<{
  policy: DmarcPolicy;
  showTooltip?: boolean;
}>();

const severity = {
  none: 'danger',
  quarantine: 'warn',
  reject: 'success',
}[policy];

const tooltip = {
  none: translate('domains.tags.policy.none.value'),
  quarantine: translate('domains.tags.policy.quarantine.value'),
  reject: translate('domains.tags.policy.reject.value'),
}[policy];

</script>

<template>
  <Tag
    v-if="showTooltip"
    class="cursor-default"
    :value="policy"
    :severity="severity"
    v-tooltip.top="$t('ip_address_modal.table.published_policy.info.tooltip') + `: ${tooltip}`"
  />
  <Tag
    v-else
    class="cursor-default"
    :value="policy"
    :severity="severity"
    v-tooltip.top="$t('domains.tags.policy.tooltip')"
  />
</template>
