<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import type { BarChartSize } from '@/Components/types/types';
import Chart from '@/Components/Charts/Chart.vue';
import { merge } from 'lodash';
import { externalTooltipHandler } from '@/Components/Charts/ChartTooltip';

const { size, options } = defineProps<{
  size?: BarChartSize;
  data: ChartData<'bar'>;
  options?: ChartOptions<'bar'>;
}>();

const classes = {
  medium: 'min-h-32',
}[size || 'medium'];

const chartOptions: ChartOptions<'bar'> = merge({}, {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    tooltip: {
      mode: 'index',
      enabled: false,
      intersect: false,
      external: externalTooltipHandler,
    },
    legend: {
      display: false,
    },
  },

  clip: false,

  scales: {
    x: {
      stacked: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
}, options);
</script>

<template>
  <Chart type="bar" :class="classes" :data="data" :options="chartOptions" :canvasProps="{'class':'mx-auto h-full w-full'}" />
</template>
